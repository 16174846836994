import logo from "./logo.svg";
import "./Assets/Css/seperate css/spacing.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../src/Components/HomePage/index";
import AboutIndex from "./Components/About Us";
import ContactIndex from "./Components/Contact Us";
import Media from "./Components/Media/Media";
import Candidates from "./Components/Candidates/Candidates";
import Vacancies from "./Components/Candidates/Vacancies";
import Cvmaker from "./Components/Candidates/Cvmaker";
import BusinessIndex from "./Components/Business/BusinessIndex";
import InovationIndex from "./Components/Innovation/InovationIndex";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css"
import '../src/Assets/Css/Solution-Service.css';
import JobDetail from "./Components/Candidates/JobDetail";

// contect api
import { JobDetailsContext } from "./Components/Candidates/JobDetailsContext";

function App() {


  return (
    <JobDetailsContext>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutIndex />} />
            {/* <Route path="/contact-us" element={<ContactIndex />} /> */}

            <Route path="/for-candidates" element={<Candidates />} />
            <Route path="/vacancies" element={<Vacancies />} />
            <Route path="/job-detail/:vacancy_id" element={<JobDetail />} />
            <Route path="/cv-maker" element={<Cvmaker />} />

            <Route path="/for-companies" element={<BusinessIndex />} />
            <Route path="/inovation-lab" element={<InovationIndex />} />
          </Routes>
        </BrowserRouter>
      </div>
    </JobDetailsContext>
  );
}

export default App;
