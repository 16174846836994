import React, { useEffect, useState } from 'react'
// import Slider from 'react-slick';
import axios from 'axios';
import Loader from '../Loader';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

const Banner = () => {
    
    const openNav = () => {
        document.getElementById("myForm").style.width = "500px";
    }

    function closeNav() {
        document.getElementById("myForm").style.width = "0";
    }
    // const [isHovered, setIsHovered] = useState(false);

    // const handleMouseEnter = () => {
    //     setIsHovered(true);
    // };

    // const handleMouseLeave = () => {
    //     setIsHovered(false);
    // };

    const [isLoading, setLoading] = useState(true)
    const [data, setdata] = useState(null)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setLoading(true)
        try {
            const response = await axios.get(
                "https://www.itelfs.com/admin/api/mainpage",
            )
            setdata(response.data)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    document.title = "ELFS";

    const [purpose, setPurpose] = useState('business')

    const [company_show, setCompanyShow] = useState(true)
    const [show_college, setCollegeShow] = useState(false)
    const [show_message, setMessageShow] = useState(true)

    const onPurposeChange = (e) => {
        setPurpose(e.target.value)
        if (e.target.value == "careers" || e.target.value == 'general') {
            setCompanyShow(false)
        } else {
            setCompanyShow(true)
        }

        if (e.target.value == "careers") {
            setCollegeShow(true)
            setMessageShow(false)
        } else {
            setCollegeShow(false)
            setMessageShow(true)
        }
    }

    const [f_name, setFirstName] = useState('')
    const [l_name, setLastName] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [file, setFile] = useState('')
    const [college, setCollege] = useState('')
    const [message, setMessage] = useState('')



    const onSubmitForm = async e => {
        e.preventDefault()
        document.getElementById("myForm").style.width = "0";
        setLoading(true)
        const formData = new FormData()
        formData.append('purspose', purpose)
        formData.append('fname', f_name)
        formData.append('lname', l_name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('message', message)
        formData.append('company', company)
        formData.append('college', college)
        formData.append('attachment', file)
        try {
            const response = await axios({
                method: "post",
                url: "https://www.itelfs.com/admin/api/getintouch",
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {

                swal({
                    icon: "success",
                    text: "Data Recorded Successfully",
                }).then(data => {
                    window.location.reload()
                })

                setLoading(false)
            })
        } catch (error) {
            swal({
                icon: "error",
                text: "Something Went Wrong",
            })
            setLoading(false)
            console.log(error)
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            <section>
                <div className='swiper-container'>
                    {data?.video_slider?.map((item, index) => {
                        return (
                            <div className="bd-single-slider-overlay  d-flex align-items-center swiper-slide bd-slider-height" key={"firts" + index}>
                                <div className="bd-slide-bg" style={{ opacity: "0.38" }}>
                                    <video src={item.hero_section_video_link} autoPlay loop playsInline muted className='w-100 bd-resp-video' ></video>
                                </div>
                                <div className="container" style={{ maxWidth: "710px" }}>
                                    <div className="row ">
                                        <div className="col-12 ">
                                            <div className=" z-index text-center bd-home-banner-title pt-35" >
                                                <h1 className="bd-slider-title mb-20 bd-icon-gradient" >{item.hero_section_video_title}</h1>
                                                <p className="mb-40 text-white bd-home-banner-text" >{item.hero_section_video_subtitle} </p>
                                                <div className="bd-slider-btn">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </section>
            <section>
                <div className=''>
                    <a className='side-fixed-btn text-white' onClick={openNav}>Get in Touch</a>
                </div>
            </section>

            {/* new Added 1 */}
            <section className='bg-banner'
                style={{ backgroundColor: "#f5fdff" }}
            >
                <div className='b-head-container bd-container-head '>
                    <div className='bd-banner-section'>
                        <div className='d-flex justify-space-between bd-resp-flex-column align-items-center mb-column-reverse'>
                            <div className='bd-banner-img'>
                                <div>
                                    <div>
                                        <Link to="/for-companies">
                                            <div className='it-box1-home' >
                                                <h3>{data?.slider_section[0].about_slider_title}</h3>
                                                <p className='mb-0'>{data?.slider_section[0].about_slider_secondtitle}</p>
                                            </div>
                                        </Link>
                                        <Link to="/for-companies">
                                            <div className='it-box1-home bg-second-color' >
                                                <h3>{data?.slider_section[0].about_slider_btntext}</h3>
                                                <p className='mb-0'>{data?.slider_section[0].about_slider_btnurl}</p>
                                            </div>
                                        </Link>

                                        <Link to="/inovation-lab">
                                            <div className='it-box1-home bg-third-color mb-0' >
                                                <h3>{data?.slider_section[0].about_slider_btncontent}</h3>
                                                <p className='mb-0'>{data?.slider_section[0].about_btntext}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 bd-respon-w-100' style={{ width: "58%" }}>
                                <div>
                                    <p className='bd-banner-text'>{data?.slider_section[0].about_btnurl} <br /> <em> <strong>{data?.slider_section[0].about_btncontent}</strong> </em></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* New Added 2 */}
            <section>
                <div className=''>
                    <div className='main-section pb-0'>
                        <div className=''>
                            <div className=' b-head-container bd-container-head'>
                                <div className='mb-20'>
                                    <h1 className='bd-second-head'><i className='bd-first-letter'>
                                        {data?.together_section[0]?.section_heading}</i>
                                        <span className='small'> {data?.together_section[0]?.section_secondheading}</span></h1>
                                </div>
                            </div>
                            <div className='d-flex align-items-center bd-resp-flex-column'>
                                <div className='col-md-5 bd-respon-w-100'>
                                    <div className='bd-side-grow'>
                                        <div className='pl-80' >
                                            <h1 className="bd-text-second ">{data?.together_section[0]?.section_title} <span>{data?.together_section[0]?.section_subtitle}</span></h1>
                                            <div className='mt-70  text-left'>
                                                <a href={data?.together_section[0]?.section_btn_url}
                                                    aria-label="Read more about" className='bd-read-more '>
                                                    {data?.together_section[0]?.section_btn_text}</a>
                                                <span className="bg" />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-7 bd-respon-w-100'>
                                    <div className='bd-secondsection-bg'>
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <h1 className='bd-number'>01</h1>
                                                <h3 className='font-size-large '>{data?.together_section[0]?.section_content_title}</h3>
                                                <p className='bd-smal-text text-justify-w87'>{data?.together_section[0]?.section_content_subtitle}</p>

                                            </div>
                                            <div className='col-lg-4'>
                                                <h1 className='bd-number '>02</h1>
                                                <h3 className='font-size-large '>{data?.together_section[0]?.section_content_title2}</h3>
                                                <p className='bd-smal-text text-justify-w93'>{data?.together_section[0]?.section_content_subtitle2}</p>

                                            </div>
                                            <div className='col-lg-4'>
                                                <h1 className='bd-number '>03</h1>
                                                <h3 className='font-size-large'>{data?.together_section[0]?.section_content_title3}</h3>
                                                <p className='bd-smal-text text-justify'>{data?.together_section[0]?.section_content_subtitle3}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* new Added 3 */}
            <section className='mt-100 mb-100'>
                <div>
                    <div className='b-head-container container-fluid bd-container-head mt-40' style={{ maxWidth: "calc(93% - 0.02px)" }}>
                        <div className='text-center mb-30 mx-auto'>
                            <h2 className="bd-fourth-head-cand">{data?.techies_section[0].techies_title}</h2>
                            <div className="mb-40">
                                <p className="bd-thirs-text">{data?.techies_section[0].techies_subtitle}</p>
                            </div>
                        </div>

                        <div className="row bd-imp-row responsive-web-flex" >

                            {
                                data?.techies.map((tech, i) => {
                                    return (
                                        <div key={i} className='col-md-3 col-sm-6 pr-10 pl-10 mobile-w-100 mb-20 mt-10'>
                                            <div className="no-hover bd-border-shadow">
                                                <div className='bd-slide-card'></div>
                                                <div className='text-center mb-20 mt-15'>
                                                    <img alt="No img" src={`https://www.itelfs.com/admin/assets/front/img//${tech.image}`} className='m-auto bd-grad' />
                                                </div>
                                                <div className="mb-20 ">
                                                    <h3 className='bd-service-headings text-center'>
                                                        {tech.title}
                                                    </h3>
                                                </div>
                                                <div className="bd-service-text mb-20 " >
                                                    <p style={{ lineHeight: "26px" }}>{tech.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }


                            {/* <div className='col-md-3 col-sm-6 pr-10 pl-10 mobile-w-100 mb-20 mt-10'>
                                <div className="no-hover bd-border-shadow">
                                    <div className='text-center mb-20 mt-10'>
                                        <img alt="No img" src={IconC3} className='m-auto  bd-grad' />
                                    </div>
                                    <div className="mb-20 ">
                                        <h3 className='bd-service-headings text-center'>Artificial <br /> Intelligence</h3>
                                    </div>
                                    <div className="bd-service-text mb-20" >
                                        <p style={{ lineHeight: "26px" }}>Harness artificial intelligence and machine learning to drive business innovation.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 col-sm-6 pr-10 pl-10 mobile-w-100 mb-20 mt-10'>
                                <div className="no-hover bd-border-shadow">
                                    <div className='text-center mobile-w-100 mb-20 mt-10'>
                                        <img alt="No img" src={IconC2} className='m-auto bd-grad' />
                                    </div>
                                    <div className="mb-20 ">
                                        <h3 className='bd-service-headings text-center'>Cloud <br />Computing </h3>
                                    </div>
                                    <div className="bd-service-text mb-20 ">
                                        <p style={{ lineHeight: "26px" }}>Deliver exceptional value across your cloud journey.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 pr-10 pl-10 mobile-w-100 mb-20 mt-10'>
                                <div className="no-hover bd-border-shadow">
                                    <div className='text-center mobile-w-100 mb-20 mt-10'>
                                        <img alt="No img" src={IconC4} className='m-auto bd-grad' />
                                    </div>
                                    <div className="mb-20 ">
                                        <h3 className='bd-service-headings text-center'>Data & <br /> Analytics</h3>
                                    </div>
                                    <div className="bd-service-text mb-20 ">
                                        <p style={{ lineHeight: "26px" }}>Optimize data to inform better decision-making.</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            {/* new Added 4 */}
            <section className=''>
                <div className='border-grey'>
                    <div className=' d-flex align-items-center bd-resp-flex-column'>
                        <div className='col-md-5 pl-60 pr-60 techies-padding'>
                            <div>
                                <h1 className='bd-third-head'>

                                    <span> {data?.speaks_section[0]?.speks_heading} </span> <br />
                                    {data?.speaks_section[0]?.speaks_subtitle}
                                </h1>
                            </div>
                            <div className='mt-20'>
                                <p className="bd-thirs-text">
                                    {data?.speaks_section[0]?.speks_overview}
                                </p>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div>
                                {
                                    data?.speaks?.map((speak) => {
                                        return (
                                            <div key={speak.id} className='row mb-20 w-100 about-mb'>
                                                <div className='icon-width'>
                                                    <img src={`https://www.itelfs.com/admin/assets/front/img//${speak.img}`} className='w-100' alt='No img' />
                                                </div>
                                                <div className='col-9 bd-about-icon'>
                                                    <h3 className='bd-service-headings'>{speak.title}</h3>
                                                    <p className='about-text'>{speak.overview}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* new Added 5 */}
            <section className='mt-100 mb-100 custom-margin'>
                <div className='b-head-container bd-container-head'>
                    <div>
                        <div className="row bd-resp-flex-column">
                            <div
                                className="col-6 bd-animate-flip pr-20 bd-respon-w-100 pe-md-4 mb-md-0 mb-4">
                                <div className='bd-elfs-cect '>
                                    <Link to="/for-companies">
                                        <div><h1>elfs Services</h1></div>
                                        <div>
                                            <i className="bi bi-arrow-right it-arrow"></i>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-6 bd-animate-flip pl-20 bd-respon-w-100 ps-md-4'>
                                <div className='bd-elfs-cect bg-green '>
                                    <Link to="/partner-with-us">
                                        <div><h1>Partner with us</h1></div>
                                        <div>
                                            <i className="bi bi-arrow-right it-arrow"></i>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="myForm" className="form-sidenav" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' }}>
                <div className='bd-sidebars'>
                    <a className="closebtn" onClick={closeNav}>&times;</a>
                    <div className="contact-head mb-30">
                        <h3>Contact Us</h3>
                    </div>
                    <div>
                        <div className="d-flex bd-radio-btn" onChange={onPurposeChange}>
                            <div className='col-md-3 pr-20'>
                                <p>Purspose*</p>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="business" defaultChecked="true" />
                                <label >Business </label><br></br>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="careers" />
                                <label >Careers </label><br></br>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="general" ight="20" />
                                <label className='pr-0' style={{ paddingRight: "0px" }}>General </label><br></br>
                            </div>
                        </div>
                        <div>
                            <form onSubmit={onSubmitForm}>
                                <div className="mt-30">
                                    <div >
                                        <input
                                            className='form-control-contact mr-20 '
                                            style={{ width: "45%" }}
                                            type='text'
                                            placeholder='First Name *'
                                            onChange={(e) => setFirstName(e.target.value)}
                                        ></input>
                                        <input
                                            style={{ width: "45%" }}
                                            className='form-control-contact ml-20 '
                                            type='text'
                                            placeholder='Last Name *'
                                            onChange={(e) => setLastName(e.target.value)}
                                        ></input>
                                    </div>
                                    {company_show == true ? (
                                        <input
                                            className='form-control-contact w-100'
                                            type='text'
                                            placeholder='Company Name *'
                                            onChange={(e) => setCompany(e.target.value)}
                                        ></input>
                                    ) : (<></>)}


                                    <input
                                        className='form-control-contact w-100'
                                        type='text'
                                        placeholder='Email Address *'
                                        onChange={(e) => setEmail(e.target.value)}
                                    ></input>
                                    <input
                                        className='form-control-contact w-100'
                                        type='text'
                                        placeholder='Phone Number *'
                                        onChange={(e) => setPhone(e.target.value)}
                                    ></input>
                                    {show_college == true ? (
                                        <>
                                            <input
                                                className='form-control-contact w-100'
                                                type='text'
                                                placeholder='College Name *'
                                                onChange={(e) => setCollege(e.target.value)}
                                            ></input>
                                            <input
                                                className='form-control-contact w-100'
                                                type='file'
                                                onChange={(e) => setFile(e.target.files[0])}
                                                placeholder='Company Name *'
                                            ></input>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {show_message == true ? (
                                        <textarea className='form-control-contact w-100'
                                            placeholder='Message *'
                                            onChange={(e) => setMessage(e.target.value)}
                                        >
                                        </textarea>) : (
                                        <></>
                                    )}

                                    <div>
                                        <input
                                            type='checkbox'
                                        ></input>
                                        <label className='bd-text-check'>
                                            Yes, I would like elfs to contact me.</label>
                                    </div>

                                    <div className='mt-20'>
                                        <button className='btn-submit' >Submit</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Banner