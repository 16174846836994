import React from 'react'
import "../../Assets/Css/Home.css"
import Navbar from './Navbar'
import Banner from './Banner'
import Footer from './Footer'

const index = () => {

  document.title = "ELFS";

  return (
    <div>
      <Navbar />
      <Banner />
      <Footer />
    </div>
  )
}

export default index