import React, { useEffect } from 'react'
import "../../Assets/Css/Home.css"
import { Link, NavLink } from 'react-router-dom';

import { useState } from 'react';

const Navbar = ({ scrollToSection }) => {

    function openNav() {
        document.getElementById("mySidenav").style.width = "100%";
    }

    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, []);


    const [navbar, setNavbar] = useState(false)
    const [stickyClass, setStickyClass] = useState('');

    // sticky Navbar
    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            // window height changed for the demo
            windowHeight > 80 ? setStickyClass('b-head') : setStickyClass('');
        }
    }

    window.addEventListener('scroll', stickNavbar);

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => window.addEventListener('scroll', stickNavbar);
    }, []);


    const change = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    return (
        <div className="">
            <nav
                // className="navbar navbar-expand-lg navbar-light"
                className={navbar ? "navbar navbar-expand-lg navbar-light" : `navbar navbar-expand-lg navbar-light ${stickyClass}`}
                style={{ position: "absolute", width: "100%", zIndex: "999" }}>
                <div className='b-head-container container-fluid bd-container-head justify-content-space-between'>
                    <Link to="/" className="navbar-brand bd-brand bd-brand2" style={{ color: "white" }} >elfs</Link>
                    <button className="navbar-toggler1 fs-1" onClick={openNav} >
                    <i className="bi bi-list"></i>
                    </button>
                    <div className=" text-right" id="navbarNavAltMarkup">

                        <div className="navbar-nav">
                            <div className='dropdown-hover'>
                                <Link className="nav-link text-white" to="/about-us">About us </Link>
                                <div className="dropdown-content">
                                    <div className="bd-hover-link ">
                                        <Link to="/about-us" onClick={() => scrollToSection('section1')}>What we do</Link>
                                    </div>
                                    <div className="bd-hover-link">
                                        <Link to="/about-us" onClick={() => scrollToSection('section2')}>Our Team</Link>
                                    </div>
                                    <div className="bd-hover-link ">
                                        <Link to="/about-us" onClick={() => scrollToSection('section3')}>Our Partners</Link>
                                    </div>
                                </div>
                            </div>

                            <div className='dropdown-hover'>
                                <NavLink className={({ isActive }) => (isActive ? 'nav-link active-tab text-white' : 'nav-link active text-white')} to="/for-companies">For Companies</NavLink>



                                <div className="dropdown-content">
                                    <div className="bd-hover-link ">
                                        <Link to="/for-companies" onClick={() => scrollToSection('business-section1')}>IT Projects Pro Consulting</Link>
                                    </div>
                                    <div className="bd-hover-link">
                                        <Link to="/for-companies" onClick={() => scrollToSection('business-section2')}>Tech Talents On Demand</Link>
                                    </div>
                                    <div className="bd-hover-link">
                                        <Link to="/for-companies" onClick={() => scrollToSection('business-section3')}>Use Cases</Link>
                                    </div>
                                </div>
                            </div>

                            <div className='dropdown-hover'>
                                <Link className="nav-link text-white"
                                    to="/for-candidates"
                                > For Candidates </Link>
                                <div className="dropdown-content">
                                    <div className="bd-hover-link">
                                        <Link to="/for-candidates" onClick={() => scrollToSection('candidates-section1')}>Apply for a Job</Link>
                                    </div>
                                    <div className="bd-hover-link">
                                        <Link to="/for-candidates" onClick={() => scrollToSection('candidates-section2')}>Submit your CV </Link>
                                    </div>
                                    {/* <div className="bd-hover-link">
                                        <Link to="/for-candidates" onClick={() => scrollToSection('candidates-section3')} >Job Search Tips</Link>
                                    </div> */}
                                    <div className="bd-hover-link">
                                        <Link to="/for-candidates" a onClick={() => scrollToSection('candidates-section4')}>ELFS principles</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='dropdown-hover'>
                                <Link className="nav-link text-white" to="/inovation-lab">Innovation Lab</Link>
                                <div className="dropdown-content">
                                    <div className="bd-hover-link">
                                        <Link to="/inovation-lab" onClick={() => scrollToSection('innovation1')}>Lab and R&D</Link>
                                    </div>
                                    <div className="bd-hover-link">
                                        <Link to="/inovation-lab" onClick={() => scrollToSection('innovation2')}>Products </Link>
                                    </div>
                                </div>
                            </div>
                            <NavLink className={({ isActive }) => (isActive ? 'nav-link active-tab text-white' : 'nav-link active text-white')} to="#">Media</NavLink>
                        </div>
                    </div>
                </div>
                <div>
                    <div id="mySidenav" className="sidenav">
                        <div className="d-flex align-items-center justify-space-between">
                            <div className='bd-sidebar-logo' >
                                <Link to="/" className='bd-sidebar-logo'>elfs</Link>
                            </div>
                            <button style={{ border: "none", backgroundColor: "transparent" }} className="closebtn pr-10" onClick={closeNav}>&times;</button>

                        </div>

                        <NavLink
                            className={({ isActive }) => (isActive ? 'nav-link active-tab' : 'nav-link active')} to="/about-us">About us</NavLink>
                        <Link to="/for-companies">For Business</Link>
                        <Link to="/for-candidates">For Candidates</Link>


                        <NavLink className={({ isActive }) => (isActive ? 'nav-link active-tab' : 'nav-link active')} to="/inovation-lab">Inovation Lab</NavLink>
                        <a href="#">Media</a>
                    </div>
                </div>
            </nav>


        </div>
    )
}

export default Navbar