import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../HomePage/Navbar'
import Footer from '../HomePage/Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Loader from '../Loader'
import swal from 'sweetalert'

// context api
import { useJobData } from './JobDetailsContext'

const Candidates = () => {

  const [dynaminCountries, setDynamicCountries] = useState([]);
  const { JobDetail, selectJobDetails } = useJobData();

  const openNav = () => {
    document.getElementById("myForm").style.width = "500px";
  }

  function closeNav() {
    document.getElementById("myForm").style.width = "0";
  }

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);

  const scrollToSection = (section) => {
    let targetSection = null;

    switch (section) {
      case 'candidates-section1':
        targetSection = section1Ref.current;
        break;
      case 'candidates-section2':
        targetSection = section2Ref.current;
        break;
      case 'candidates-section3':
        targetSection = section3Ref.current;
        break;
      case 'candidates-section4':
        targetSection = section4Ref.current;
        break;
      default:
        break;
    }

    if (targetSection) {
      const topOffset = targetSection.offsetTop;;
      const offset = 90;
      const scrollOptions = {
        top: topOffset - offset,
        behavior: 'smooth',
      };
      // targetSection.scrollIntoView(scrollOptions);
      window.scrollTo(scrollOptions)
    }
  };

  document.title = "Candidates | ELFS";

  const [isLoading, setLoading] = useState(true)
  const [data, setdata] = useState(null)

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        "https://www.itelfs.com/admin/api/candidates",
      )
      setdata(response.data.candidates_page)

      const response2 = await axios.get(
        "https://www.itelfs.com/admin/api/vacancies",
      )
      setDynamicCountries(response2.data.vacancies_page.country)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [f_name, setFirstName] = useState('')
  const [l_name, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [file, setFile] = useState('')
  const [jobName, setJobName] = useState('')


  const onSubmitForm = async (e) => {
    e.preventDefault()
    document.getElementById("myForm").style.width = "0";
    setLoading(true)
    const formData = new FormData()
    formData.append('fname', f_name)
    formData.append('lname', l_name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('message', jobName)
    formData.append('attachment', file)
    try {
      const response = await axios({
        method: "post",
        url: "https://www.itelfs.com/admin/api/apply_for",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {

        swal({
          icon: "success",
          text: "Data Recorded Successfully",
        }).then(data => {
          window.location.reload()
        })
        setLoading(false)
      })
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      })
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <div>
      {isLoading && (<Loader />)}
      <Navbar scrollToSection={scrollToSection} />
      {/* <Footer/> */}
      <section>
        <div className='swiper-container'>
          {data?.candidate_breadcrumb?.map((bread, i) => {
            return (
              <div className="bd-single-slider-overlay  d-flex align-items-center swiper-slide bd-banner-height" key={"bread" + i}>
                <div className="bd-slide-bg" style={{ opacity: "0.38" }}>
                  <img alt="no img" src={`https://www.itelfs.com/admin/assets/front/img//${bread.candidatebreadcrumb_bg}`} className='w-100 respon-height' />
                </div>
                <div className="container" style={{ maxWidth: "710px" }}>
                  <div className="row ">
                    <div className="col-12 ">
                      <div className=" z-index text-center" style={{ position: "relative", zIndex: "999", top: "30px" }}>
                        <h1 className="bd-slider-title mb-20 bd-icon-gradient" >{bread.candidatebreadcrumb_heading}</h1>
                        <p className="mb-40 text-white" >{bread.candidatebreadcrumb_overview}</p>
                        <div className="bd-slider-btn">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

        </div>
      </section>

      <section>
        <div className=''>
          <a className='side-fixed-btn text-white' onClick={openNav}>Submit CV</a>
        </div>
      </section>

      <section className='mb-50 mt-50' ref={section1Ref}>
        <div className='pt-50'>
          {data?.job_section?.map((jobs, i) => {
            return (
              <div className=' d-flex align-items-center bd-resp-flex-column' key={"jobs" + i}>
                <div className='col-md-5 pl-60 pr-60 bd-respon-w-100'>
                  <div className='mt-35'>
                    <h1 className='bd-third-head'>
                      {jobs.jobsection_heading}
                      <br />
                      <span>{jobs.jobsection_secondhead}</span>

                    </h1>
                    {/* <div className='mt-10'>
                      <p className="bd-thirs-text">
                        {jobs.jobsection_overview}
                      </p>
                    </div> */}
                  </div>
                  {/* <div className='mt-35'>
                    <div>
                      <a href={jobs.jobsection_btn_url} className='bd-all-job-btn'>{jobs.jobsection_btn_text}</a>
                    </div>
                  </div> */}
                </div>
                <div className='col-md-7 bd-respon-w-100'>
                  <div className="bd-job-apply">
                    <div>
                      <div className='text-center'>
                        <p className='filter-text'>{jobs.jobsection_title}</p>
                      </div>
                    </div>
                    <div className="d-flex bd-resp-flex-column justify-content-center bd-hover-effect">
                      <div className='col-md-4 bd-box-filter box-effect1' >
                        <div className=''>
                          <div className=''>
                            <select className="bd-capsule-sevice-select"
                              value={JobDetail.country}
                              name='country' onChange={selectJobDetails}>
                              <option value="">Country / City</option>

                              {
                                dynaminCountries?.map((country) => {
                                  return (
                                    <option value={country.name}>{country.name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 bd-box-filter box-effect2'>
                        <div>
                          <div className=''>
                            <select className="bd-capsule-sevice-select"
                              value={JobDetail.jobName}
                              name='jobName' onChange={selectJobDetails}>
                              <option value="">Technology </option>
                              <option value="Machine Learning">Machine Learning</option>
                              <option value="IT Business Analyst">IT Business Analyst</option>
                              <option value=".NET Developer">.NET Developer</option>
                              <option value="Blockchain">Blockchain</option>
                              <option value="IoT & Connected">IoT & Connected</option>
                              <option value="Big Data">Big Data</option>
                              <option value="Deveops">Deveops</option>
                              <option value="Augmented Reality">Augmented Reality</option>
                              <option value="Mobile & Wearable">Mobile & Wearable</option>
                              <option value="UX / UI">UX / UI</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 bd-box-filter box-effect3'>
                        <div>
                          <div className=''>
                            <select className="bd-capsule-sevice-select"
                              value={JobDetail.expLevel}
                              name='expLevel' onChange={selectJobDetails}>
                              <option value="">Experience Level</option>
                              <option value="Entry level">Entry level</option>
                              <option value="Mid-Senior level">Mid-Senior level</option>
                              <option value="Director, Executive">Director, Executive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex bd-resp-flex-column justify-content-center">
                      <div className='col-md-4 bd-box-filter box-effect4' >
                        <div className=''>
                          <div className=''>
                            <select className="bd-capsule-sevice-select"
                              value={JobDetail.jobType}
                              name='jobType' onChange={selectJobDetails}>
                              <option value="">Job Type</option>
                              <option value="Full-time">Full-time</option>
                              <option value="Part-time"> Part-time</option>
                              <option value="Contract">Contract</option>
                              <option value="Temporary">Temporary</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 bd-box-filter box-effect5'>
                        <div>
                          <div className=''>
                            <select className="bd-capsule-sevice-select"
                              value={JobDetail.jobMode}
                              name='jobMode' onChange={selectJobDetails}>
                              <option value="">On-site / remote</option>
                              <option value="On-site">On-site</option>
                              <option value="Remote"> Remote</option>
                              <option value="Hybrid" >Hybrid</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 bd-box-filter box-effect6'>
                        <div>
                          <div className=''>
                            <select className="bd-capsule-sevice-select"
                              value={JobDetail.jobHistory}
                              name='jobHistory' onChange={selectJobDetails}>
                              <option value="">Date Posted </option>
                              <option value="Past week">Past week</option>
                              <option value="Past month"> Past month</option>
                              <option value="All">All</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='text-center mt-30'>
                      <Link
                        to="/vacancies" className='bd-all-job-btn'>Apply For a Job</Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

        </div>
      </section>

      <section className='pb-50 pt-70' ref={section2Ref}>
        <div className=''>
          {data?.cv_section?.map((cv, i) => {
            return (
              <div className=' d-flex bd-resp-flex-column' key={"cv" + i}>
                <div className='col-md-5 pl-60 pr-60 bd-Fourthsection-bg'>
                  <div className='d-flex align-items-center h-100 pl-20'>
                    <h1 className='bd-third-head text-white'>
                      {cv.cvsection_heading.split(' ').slice(0, 1)}
                      <br />
                      {cv.cvsection_heading.split(' ').slice(1).join(' ')}

                    </h1>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div className='pl-45 pr-45 pt-20 pb-20'>
                    <div>
                      <div>
                        <div className='d-flex'>
                          <p className="bd-thirs-text ">
                            {cv.cvsection_overview}
                          </p>
                        </div>
                        <div className='mt-10 '>
                          <a href={cv.cvsection_btn_url} className='bd-all-job-btn' onClick={openNav}> {cv.cvsection_btn_text}</a>
                        </div>
                        <div className='d-flex mt-100'>
                          <p className="bd-thirs-text ">
                            {cv.cvsection_overview1}
                          </p>
                        </div>
                        <div className=' mt-10'>
                          <Link to="/cv-maker" className='bd-all-job-btn'>
                            {cv.cvsection_btn_text1}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

        </div>
      </section>

      {/* new Added section */}
      <section className='' >
        <div className=''>
          <div className=' d-flex align-items-center bd-resp-flex-column'>
            <div className='col-md-5 pl-60 pr-60 bd-respon-w-100 padding-custom'>
              <div className='pt-30 pb-30 pl-15'>
                <h1 className='bd-third-head '>
                  {data?.hire_section[0].hire_heading}
                  <br />
                  <span>{data?.hire_section[0].hire_secondheading}</span>

                </h1>
              </div>
            </div>
            <div className='col-md-7'>
              <div className='p-5 mb-p-3'>
                <div>
                  <div>
                    <div className='mt-20'>
                      <div className='d-flex'>
                        <ul className='bd-points-candi'>
                          <li className='pb-30'>
                            <span className='connected-dot'></span>
                            <p className='mb-10 ' style={{ fontSize: "20px" }}>{data?.hire_section[0].hire_overview}</p>
                          </li>
                          <li className='pb-30'>
                            <span className='connected-dot'></span>
                            <p className='mb-10' style={{ fontSize: "20px" }}> {data?.hire_section[0].hire_title}</p>
                          </li>
                          <li className='pb-30'>
                            <span className='connected-dot'></span>
                            <p className='mb-10' style={{ fontSize: "20px" }}> {data?.hire_section[0].hire_subtitle}</p>
                          </li>
                          <li className='pb-30'>
                            <span className='connected-dot'></span>
                            <p className='mb-10' style={{ fontSize: "20px" }}> {data?.hire_section[0].hire_title1}</p>
                          </li>
                          <li className='pb-30'>
                            <span className='connected-dot'></span>
                            <p className='mb-10' style={{ fontSize: "20px" }}> {data?.hire_section[0].hire_subtitle1} </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =========================== */}

      {/* new added 2 */}
      <section className='mt-20 mb-80' ref={section4Ref}>
        <div>
          <div className='text-center'>
            <h2 className='bd-fourth-head-cand'> {data?.principles_section[0].principles_section_title} </h2>
          </div>
          <div className='b-head-container container-fluid bd-container-head mt-40' style={{ maxWidth: "1280px" }}>
            <div className="row bd-imp-row responsive-web-flex bd-resp-flex-column align-items-center" >

              {
                data?.principles.map((principle, i) => {
                  return (
                    <div key={i} className='col-md-4 col-sm-6 pr-10 pl-10 mobile-w-100 mb-20 mt-10 width-20 bd-respon-w-100'>
                      <div className="bd-service-box-princ h-320 ">
                        <div className='text-center mb-20'>
                          <span className='priciple-count'>0{i + 1}</span>
                        </div>
                        <div className="mb-20 mt-30">
                          <h3 className='bd-service-headings text-center'>
                            {principle.principles_title}</h3>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </section>
      {/* =========================== */}


      <Footer />


      <div id="myForm" className="form-sidenav">
        <div className='bd-sidebars'>
          <a href='#' className="closebtn" style={{ cursor: "pointer" }} onClick={closeNav}>&times;</a>
          <div className="contact-head mb-30">
            <h3 style={{ fontWeight: "600" }}>Submit Your CV</h3>
          </div>
          <div>
            <form onSubmit={onSubmitForm}>
              <div>
                <div className="mt-30">
                  <label className='form-label-cv'>First Name</label>
                  <input
                    aria-label="Search"
                    className='custome-font-form-cv'
                    value={f_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder='Enter First Name'
                    required
                    type='text'
                  />
                  <label className='form-label-cv'>Last Name</label>
                  <input
                    className='custome-font-form-cv'
                    placeholder='Enter Last Name'
                    value={l_name}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    type='text'
                  />
                  <label className='form-label-cv'>Phone Number</label>
                  <input
                    className='custome-font-form-cv'
                    placeholder='Enter Phone Number'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    type='number'
                  />
                  <label className='form-label-cv'>Email Address </label>
                  <input
                    className='custome-font-form-cv'
                    placeholder='Enter Email Address '
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type='email'
                  />
                  <label className='form-label-cv'>Applying For </label>
                  <input
                    className='custome-font-form-cv'
                    placeholder='Enter the job you are applying for'
                    value={jobName}
                    onChange={(e) => setJobName(e.target.value)}
                    required
                    type='text'
                  />
                  <label className='form-label-cv'>Upload CV </label>
                  <input
                    className='custome-font-form-cv'
                    placeholder='Enter Job Applying For'
                    onChange={(e) => setFile(e.target.files[0])}
                    required
                    type='file'
                  />

                  <div className='mt-10'>
                    <button type='submit' className='btn-submit'>Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Candidates