import React from 'react'
import Navbar from '../HomePage/Navbar'
import Footer from '../HomePage/Footer'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import Loader from '../Loader'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

import { useJobData } from './JobDetailsContext'

const Vacancies = (props) => {

    const [isLoading, setLoading] = useState(true)
    const [data, setdata] = useState([])
    const [filterData, setFilterData] = useState([])

    const { JobDetail } = useJobData();

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setLoading(true)
        try {
            const response = await axios.get(
                "https://www.itelfs.com/admin/api/vacancies",
            )
            setdata(response.data.vacancies_page)

            const filteredJobs = response.data.vacancies_page.vacancies.filter((job) => {
                const locationMatch =
                    JobDetail.country === '' || job.vacancies_location.includes(JobDetail.country);

                const titleMatch =
                    JobDetail.jobName === '' ||
                    job.vacancies_title.toLowerCase().includes(JobDetail.jobName.toLowerCase());

                const jobTypeMatch =
                    JobDetail.jobType === '' ||
                    job.vacancies_job.toLowerCase() === JobDetail.jobType.toLowerCase();

                const expLevel =
                    JobDetail.expLevel === '' ||
                    job.experience_level.toLowerCase() === JobDetail.expLevel.toLowerCase();

                const mode =
                    JobDetail.jobMode === '' ||
                    job.remote.toLowerCase() === JobDetail.jobMode.toLowerCase();

                return locationMatch && titleMatch && jobTypeMatch && expLevel && mode;
            });

            setFilterData(filteredJobs)

            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const openNav = () => {
        document.getElementById("myForm").style.width = "500px";
    }

    function closeNav() {
        document.getElementById("myForm").style.width = "0";
    }

    const [f_name, setFirstName] = useState('')
    const [l_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [file, setFile] = useState('')
    const [jobName, setJobName] = useState('')


    const onSubmitForm = async (e) => {
        e.preventDefault()
        document.getElementById("myForm").style.width = "0";
        setLoading(true)
        const formData = new FormData()
        formData.append('fname', f_name)
        formData.append('lname', l_name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('message', jobName)
        formData.append('attachment', file)
        try {
            const response = await axios({
                method: "post",
                url: "https://www.itelfs.com/admin/api/apply_for",
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {

                swal({
                    icon: "success",
                    text: "Data Recorded Successfully",
                }).then(data => {
                    window.location.reload()
                })

                setLoading(false)
            })
        } catch (error) {
            swal({
                icon: "error",
                text: "Something Went Wrong",
            })
            setLoading(false)
            console.log(error)
        }
    }


    return (
        <div>
            {isLoading && (<Loader />)}
            <Navbar />
            <section>
                <div className='swiper-container'>
                    {data.vacancies_breadcrumb?.map((bread, i) => {
                        return (
                            <div className="bd-single-slider-overlay  d-flex align-items-center swiper-slide bd-banner-height" key={"bread" + i}>
                                <div className="bd-slide-bg" style={{ opacity: "0.38" }}>
                                    <img alt="no img" src={`https://www.itelfs.com/admin/assets/front/img//${bread.vacanciesbreadcrumb_bg}`} className='w-100 respon-height' />
                                </div>
                                <div className="container">
                                    <div className="row ">
                                        <div className="col-12 ">
                                            <div className=" z-index text-center" style={{ position: "relative", zIndex: "999", top: "30px" }}>
                                                <h1 className="bd-slider-title mb-20 bd-icon-gradient" >{bread.vacanciesbreadcrumb_heading}</h1>
                                                <p className="mb-40 text-white" >{bread.vacanciesbreadcrumb_overview}</p>
                                                <div className="bd-slider-btn">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </section>

            <section>
                <div className=''>
                    <a className='side-fixed-btn text-white' onClick={openNav}>Get in Touch</a>
                </div>
            </section>


            <section className='mt-80 mb-80'>
                <div className='b-head-container container-fluid bd-container-head'>
                    {
                        filterData.length === 0 ? null :
                        <div className='mb-50'>
                            <div className='mb-20'>
                                <h4 className='filter-head'>Choose your specialisation</h4>
                            </div>
                            <div className='d-flex bd-flex-wrap'>
                                {
                                    JobDetail.country !== "" ?
                                        <div>
                                            <a className='bd-btn-filter ml-0'> {JobDetail.country}</a>
                                        </div> : null
                                }
                                {
                                    JobDetail.jobName !== "" ?
                                        <div>
                                            <a className='bd-btn-filter'>{JobDetail.jobName}</a>
                                        </div> : null
                                }
                                {
                                    JobDetail.expLevel !== "" ?
                                        <div>
                                            <a className='bd-btn-filter'>{JobDetail.expLevel}</a>
                                        </div> : null
                                }
                                {
                                    JobDetail.jobType !== "" ?
                                        <div>
                                            <a className='bd-btn-filter'>{JobDetail.jobType}</a>
                                        </div> : null
                                }
                                {
                                    JobDetail.jobMode !== "" ?
                                        <div>
                                            <a className='bd-btn-filter'>{JobDetail.jobMode}</a>
                                        </div> : null
                                }
                                {
                                    JobDetail.jobHistory !== "" ?
                                        <div>
                                            <a className='bd-btn-filter'>{JobDetail.jobHistory}</a>
                                        </div> : null
                                }

                                {
                                    JobDetail.country === "" &&
                                    JobDetail.jobName === "" &&
                                    JobDetail.expLevel === "" &&
                                    JobDetail.jobType === "" &&
                                    JobDetail.jobMode === "" &&
                                    JobDetail.jobHistory === "" &&
                                    <div>
                                        <a className='bd-btn-filter'>All Jobs & Vacancies</a>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className='row '>
                        {
                            filterData.length ?
                                filterData?.map((vac, i) => {
                                    return (
                                        <div className='col-6 mb-30 bd-respon-w-100' key={i + "vac"}>
                                            <div className='bd-vacancy-box' style={{ minHeight: "420px", overflow: "hidden" }}>
                                                <h2>{vac.vacancies_title}</h2>
                                                <h3 className='mt-25'>{vac.vacancies_subtitle}</h3>
                                                <div className='mt-30'>
                                                    <p>{vac.vacancies_overview.replace(/<[^>]+>/g, '').slice(0, 200) + " ..."}</p>
                                                </div>
                                                <div className="d-flex  justify-space-between col-md-7 mt-20">
                                                    <h5>{vac.vacancies_location}</h5>
                                                    <h5>{vac.vacancies_job}</h5>
                                                </div>
                                                <div className="d-flex bd-resp-flex-column justify-space-between mt-50">
                                                    <div>
                                                        <span><Link to={`//job-detail/${vac.id}`}>See Description</Link></span>
                                                    </div>
                                                    <div className='mobile-mt-20'>
                                                        <a className='apply-job-btn' onClick={openNav}>Apply</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :

                                <div className='b-head-container container-fluid bd-container-head mt-40' >
                                    <div className='bd-inovative2 text-center'>
                                        <h3 className='no-vacancies'>No Vacancies Available Right Now ! </h3>
                                        <div className='mt-30'>
                                            <a href='#' className='bd-all-job-btn' style={{ width: "400px" }} onClick={openNav}> Submit Your CV For Future Opening's</a>
                                        </div>
                                    </div>
                                </div>
                        }


                    </div>
                </div>
            </section>

            <Footer />

            <div id="myForm" className="form-sidenav">
                <div className='bd-sidebars'>
                    <a href='#' className="closebtn" style={{ cursor: "pointer" }} onClick={closeNav}>&times;</a>
                    <div className="contact-head mb-30">
                        <h3 style={{ fontWeight: "600" }}>Submit Your CV</h3>
                    </div>
                    <div>
                        <form onSubmit={onSubmitForm}>
                            <div>
                                <div className="mt-30">
                                    <label className='form-label-cv'>First Name</label>
                                    <input
                                        aria-label="Search"
                                        className='custome-font-form-cv'
                                        value={f_name}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder='Enter First Name'
                                        required
                                        type='text'
                                    />
                                    <label className='form-label-cv'>Last Name</label>
                                    <input
                                        className='custome-font-form-cv'
                                        placeholder='Enter Last Name'
                                        value={l_name}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                        type='text'
                                    />
                                    <label className='form-label-cv'>Phone Number</label>
                                    <input
                                        className='custome-font-form-cv'
                                        placeholder='Enter Phone Number'
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                        type='number'
                                    />
                                    <label className='form-label-cv'>Email Address </label>
                                    <input
                                        className='custome-font-form-cv'
                                        placeholder='Enter Email Address '
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        type='email'
                                    />
                                    <label className='form-label-cv'>Applying For </label>
                                    <input
                                        className='custome-font-form-cv'
                                        placeholder='Enter the job you are applying for'
                                        value={jobName}
                                        onChange={(e) => setJobName(e.target.value)}
                                        required
                                        type='text'
                                    />
                                    <label className='form-label-cv'>Upload CV </label>
                                    <input
                                        className='custome-font-form-cv'
                                        placeholder='Enter Job Applying For'
                                        onChange={(e) => setFile(e.target.files[0])}
                                        required
                                        type='file'
                                    />

                                    <div className='mt-10'>
                                        <button type='submit' className='btn-submit'>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* <div id="myForm" className="form-sidenav" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' }}>
                <div className='bd-sidebars'>
                    <a className="closebtn" onClick={closeNav}>&times;</a>
                    <div className="contact-head mb-30">
                        <h3>Contact Us</h3>
                    </div>
                    <div>
                        <div className="d-flex bd-radio-btn" onChange={onPurposeChange}>
                            <div className='col-md-3 pr-20'>
                                <p>Purspose*</p>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="business" defaultChecked="true" />
                                <label >Business </label><br></br>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="careers" />
                                <label >Careers </label><br></br>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="general" ight="20" />
                                <label className='pr-0' style={{ paddingRight: "0px" }}>General </label><br></br>
                            </div>
                        </div>
                        <div>
                            <form onSubmit={onSubmitForm}>
                                <div className="mt-30">
                                    <div >
                                        <input
                                            className='form-control-contact mr-20 '
                                            style={{ width: "45%" }}
                                            type='text'
                                            placeholder='First Name *'
                                            onChange={(e) => setFirstName(e.target.value)}
                                        ></input>
                                        <input
                                            style={{ width: "45%" }}
                                            className='form-control-contact ml-20 '
                                            type='text'
                                            placeholder='Last Name *'
                                            onChange={(e) => setLastName(e.target.value)}
                                        ></input>
                                    </div>
                                    {company_show == true ? (
                                        <input
                                            className='form-control-contact w-100'
                                            type='text'
                                            placeholder='Company Name *'
                                            onChange={(e) => setCompany(e.target.value)}
                                        ></input>
                                    ) : (<></>)}


                                    <input
                                        className='form-control-contact w-100'
                                        type='text'
                                        placeholder='Email Address *'
                                        onChange={(e) => setEmail(e.target.value)}
                                    ></input>
                                    <input
                                        className='form-control-contact w-100'
                                        type='text'
                                        placeholder='Phone Number *'
                                        onChange={(e) => setPhone(e.target.value)}
                                    ></input>
                                    {show_college == true ? (
                                        <>
                                            <input
                                                className='form-control-contact w-100'
                                                type='text'
                                                placeholder='College Name *'
                                                onChange={(e) => setCollege(e.target.value)}
                                            ></input>
                                            <input
                                                className='form-control-contact w-100'
                                                type='file'
                                                onChange={(e) => setFile(e.target.files[0])}
                                                placeholder='Company Name *'
                                            ></input>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {show_message == true ? (
                                        <textarea className='form-control-contact w-100'
                                            placeholder='Message *'
                                            onChange={(e) => setMessage(e.target.value)}
                                        >
                                        </textarea>) : (
                                        <></>
                                    )}

                                    <div>
                                        <input
                                            type='checkbox'
                                        ></input>
                                        <label className='bd-text-check'>
                                            Yes, I would like elfs to contact me.</label>
                                    </div>

                                    <div className='mt-20'>
                                        <button className='btn-submit' >Submit</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Vacancies