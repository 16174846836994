import { createContext, useContext, useState } from 'react';

const JobDataContext = createContext();

export const useJobData = () => {
    return useContext(JobDataContext);
};

export const JobDetailsContext = ({ children }) => {

    const [JobDetail, setJobDetails] = useState({
        country: "",
        jobName: "",
        expLevel: "",
        jobType: "",
        jobMode: "",
        jobHistory: ""
    })

    const selectJobDetails = (e) => {
        setJobDetails(
            {
                ...JobDetail,
                [e.target.name]: e.target.value
            }
        )
    }

    return (
        <JobDataContext.Provider value={{ JobDetail, selectJobDetails }}>
            {children}
        </JobDataContext.Provider>
    );
};