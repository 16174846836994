import React, { useEffect, useRef, useState } from "react";
import Navbar from "../HomePage/Navbar";
import "../../Assets/Css/About.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../HomePage/Footer";
import axios from "axios";
import Loader from "../Loader";
import { Link } from "react-router-dom";

import swal from "sweetalert";

const AboutIndex = () => {

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const scrollToSection = (section) => {
    let targetSection = null;

    switch (section) {
      case 'section1':
        targetSection = section1Ref.current;
        break;
      case 'section2':
        targetSection = section2Ref.current;
        break;
      case 'section3':
        targetSection = section3Ref.current;
        break;
      default:
        break;
    }

    if (targetSection) {
      const topOffset = targetSection.offsetTop;;
      const offset = 70;
      const scrollOptions = {
        top: topOffset - offset,
        behavior: 'smooth',
      };
      // targetSection.scrollIntoView(scrollOptions);
      window.scrollTo(scrollOptions)
    }
  };
  document.title = "About Us | ELFS";


  const [isLoading, setLoading] = useState(true)
  const [data, setdata] = useState([])

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        "https://www.itelfs.com/admin/api/about",
      )
      setdata(response.data.about_page)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }


  const openNav = () => {
    document.getElementById("myForm").style.width = "500px";
  }

  function closeNav() {
    document.getElementById("myForm").style.width = "0";
  }


  const [purpose, setPurpose] = useState('business')

  const [company_show, setCompanyShow] = useState(true)
  const [show_college, setCollegeShow] = useState(false)
  const [show_message, setMessageShow] = useState(true)

  const onPurposeChange = (e) => {
    setPurpose(e.target.value)
    if (e.target.value == "careers" || e.target.value == 'general') {
      setCompanyShow(false)
    } else {
      setCompanyShow(true)
    }

    if (e.target.value == "careers") {
      setCollegeShow(true)
      setMessageShow(false)
    } else {
      setCollegeShow(false)
      setMessageShow(true)
    }
  }


  const [f_name, setFirstName] = useState('')
  const [l_name, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [file, setFile] = useState('')
  const [college, setCollege] = useState('')
  const [message, setMessage] = useState('')



  const onSubmitForm = async e => {
    e.preventDefault()
    document.getElementById("myForm").style.width = "0";
    setLoading(true)
    const formData = new FormData()
    formData.append('purspose', purpose)
    formData.append('fname', f_name)
    formData.append('lname', l_name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('message', message)
    formData.append('company', company)
    formData.append('college', college)
    formData.append('attachment', file)
    try {
      const response = await axios({
        method: "post",
        url: "https://www.itelfs.com/admin/api/getintouch",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {

        swal({
          icon: "success",
          text: "Data Recorded Successfully",
        }).then(data => {
          window.location.reload()
        })

        setLoading(false)
      })
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      })
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <div>
      {isLoading && (<Loader />)}
      <Navbar scrollToSection={scrollToSection} />

      <section>
        <div className='swiper-container'>
          {data.about_breadcrumb?.map((bread, index) => {
            return (
              <div className="bd-single-slider-overlay  d-flex align-items-center swiper-slide bd-banner-height" key={"first" + index}>
                <div className="bd-slide-bg" style={{ opacity: "0.38" }}>
                  <img alt="no img" src={`https://www.itelfs.com/admin/assets/front/img//${bread.aboutbreadcrumb_bg}`} className='w-100 respon-height' />
                </div>
                <div className="container" style={{ maxWidth: "710px" }}>
                  <div className="row ">
                    <div className="col-12 ">
                      <div className=" z-index text-center" style={{ position: "relative", zIndex: "999", top: "30px" }}>
                        <h1 className="bd-slider-title mb-20 bd-icon-gradient" >{bread.aboutbreadcrumb_heading}</h1>
                        <p className="mb-40 text-white" >{bread.aboutbreadcrumb_overview} </p>
                        <div className="bd-slider-btn">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

        </div>
      </section>

      {/* get in touch btn */}
      <section>
        <div className=''>
          <a className='side-fixed-btn text-white' onClick={openNav}>Get in Touch</a>
        </div>
      </section>

      <section ref={section1Ref}>
        <div className=''>
          <div className=' pb-0'>
            <div className=''>
              {data.about_section?.map((about, index) => {
                return (
                  <div className='d-flex align-items-center bd-resp-flex-column' key={"about" + index}>
                    <div className='col-md-5'>
                      <div className='bd-side-grow pl-60 pr-60 '>
                        <div className=" ">
                          <div >
                            <h1 className="bd-third-head text-left">
                              {about.aboutintro_heading}<br /><span>{about.aboutintro_heading_color}</span>
                            </h1>
                          </div>
                          {/* <div className="mt-20">
                            <p className="bd-thirs-text text-left">{about.aboutintro_subtitle}</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-7'>
                      <div className='bd-secondsection-bg'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            <h1 className='bd-number'>01</h1>
                            <h3 className='font-size-large '>{about.aboutintro_content_title}</h3>
                            <p className='bd-smal-text'>{about.aboutintro_content_subtitle}</p>

                          </div>
                          <div className='col-lg-4'>
                            <h1 className='bd-number '>02</h1>
                            <h3 className='font-size-large '>{about.aboutintro_content_title2}</h3>
                            <p className='bd-smal-text'>{about.aboutintro_content_subtitle2}</p>

                          </div>
                          <div className='col-lg-4'>
                            <h1 className='bd-number '>03</h1>
                            <h3 className='font-size-large'>{about.aboutintro_content_title3}</h3>
                            <p className='bd-smal-text'>{about.aboutintro_content_subtitle3}</p>

                          </div>
                        </div>
                        <div>
                          <div className='mt-50'>
                            <a href={about.aboutintro_btn_url} className='bd-oval-btn'>{about.aboutintro_btn_text}</a>
                            <span className="bg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}


            </div>
          </div>
        </div>
      </section>

      <section className="mt-50 mb-50" ref={section2Ref}>
        <div className=''>
          <div className=' pb-0'>
            <div className=''>
              <div className='d-flex align-items-center bd-resp-flex-column'>
                <div className='col-md-5 bd-respon-w-100'>
                  <div className='bd-side-grow pl-60 pr-60 '>
                    <div className=" ">
                      {data.team_section?.map((team, i) => {
                        return (
                          <div key={'team' + i} >
                            <h1 className="bd-third-head text-left">
                              {team.team_section_title}<br /><span>{team.team_section_subtitle}</span>
                            </h1>
                          </div>
                        )
                      })}

                    </div>
                  </div>
                </div>
                <div className='col-md-7 bd-respon-w-100'>
                  <div className="our-team-sect">
                    <div className="d-flex mb-20 bd-resp-flex-column flex-wrap">
                      {data.team?.map((teams, i) => {
                        return (
                          <div className="col-md-6 bd-no-padding pr-15 mb-15" key={'teams' + i}>
                            <div className="content">
                              <div className="content-overlay"></div>
                              <div>
                                <img alt="no img" src={`https://www.itelfs.com/admin/assets/front/img//members/${teams.image}`} className="w-100 " height="250" style={{objectFit:'contain'}}></img>
                              </div>
                              <div className="content-details fadeIn-bottom">
                                <h3 className="content-title">{teams.name}
                                </h3>
                                <p className="content-text">{teams.rank}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className=" mb-80" ref={section3Ref}>
        <div className=''>
          <div className=' pb-0'>
            <div className=''>
              <div className='d-flex align-items-center bd-resp-flex-column'>
                <div className='col-md-5'>
                  <div className='bd-side-grow pl-60 pr-60 '>
                    <div className=" ">
                      {data.partner_section?.map((part, i) => {
                        return (
                          <div key={"part" + i} >
                            <h1 className="bd-third-head text-left">
                              {part.partner_section_title}<br /><span>{part.partner_section_secondtitle}</span>
                            </h1>
                            <div className="mt-1">
                              <p className="bd-thirs-text text-left">{part.partner_section_description}</p>
                            </div>
                            <div className='mt-70 text-left'>
                              <Link to="/partner-with-us" className='bd-read-more ' style={{ width: "200px" }}>Partner With Us</Link>
                              <span className="bg" />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div className="">
                    <div>
                      <div className="d-flex  justify-content-center bd-hover-effect">
                        <div className='col-md-4 bd-box-size box-effect1 box-customer' >
                          <div className=''>
                            <div className='bd-capsule-sevice capsule-1 cutomer-sect part-cap'>
                              <p className="mb-0">Coming </p>Soon
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 bd-box-size box-effect2 box-customer'>
                          <div>
                            <div className='bd-capsule-sevice capsule-2 cutomer-sect part-cap'>
                              <p className="mb-0">Coming </p>Soon
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 bd-box-size box-effect3 box-customer'>
                          <div>
                            <div className='bd-capsule-sevice capsule-3 cutomer-sect part-cap'>
                              <p className="mb-0">Coming </p>Soon
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center bd-hover-effect">
                        <div className='col-md-4 bd-box-size box-effect1 box-customer' >
                          <div className=''>
                            <div className='bd-capsule-sevice capsule-1 cutomer-sect part-cap'>
                              <p className="mb-0">Coming </p>Soon
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 bd-box-size box-effect2 box-customer'>
                          <div>
                            <div className='bd-capsule-sevice capsule-2 cutomer-sect part-cap'>
                              <p className="mb-0">Coming </p>Soon
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 bd-box-size box-effect3 box-customer'>
                          <div>
                            <div className='bd-capsule-sevice capsule-3 cutomer-sect part-cap'>
                              <p className="mb-0">Coming </p>Soon
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center bd-hover-effect">
                        <div className='col-md-4 bd-box-size box-effect1 box-customer' >
                          <div className=''>
                            <div className='bd-capsule-sevice capsule-1 cutomer-sect part-cap'>
                              <p className="mb-0">Coming </p>Soon
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 bd-box-size box-effect2 box-customer'>
                          <div>
                            <div className='bd-capsule-sevice capsule-2 cutomer-sect part-cap'>
                              <p className="mb-0">Coming </p>Soon
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 bd-box-size box-effect3 box-customer '>
                          <div>
                            <div className='bd-capsule-sevice capsule-3 cutomer-sect part-cap'>
                              <p className="mb-0">Coming </p>Soon
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* <div className="our-team-sect">
                    <div>
                      <div className="d-flex  justify-content-center bd-hover-effect flex-wrap">
                        {data.partner?.map((partner, i) => {
                          return (
                            <div className='col-md-4 bd-box-size box-effect1 box-customer' key={i + "partner"} >
                              <div className=''>
                                <div className='bd-capsule-sevice capsule-1 cutomer-sect'>
                                  <img alt="no img" src={`https://www.itelfs.com/admin/assets/front/img//partners/${partner.image}`} width="143" height="55"></img>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      {/* contact form */}
      <div id="myForm" className="form-sidenav" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' }}>
        <div className='bd-sidebars'>
          <a className="closebtn" onClick={closeNav}>&times;</a>
          <div className="contact-head mb-30">
            <h3>Contact Us</h3>
          </div>
          <div>
            <div className="d-flex bd-radio-btn" onChange={onPurposeChange}>
              <div className='col-md-3 pr-20'>
                <p>Purspose*</p>
              </div>
              <div className='col-md-3'>
                <input type="radio" id="html" name="fav_language" value="business" defaultChecked="true" />
                <label >Business </label><br></br>
              </div>
              <div className='col-md-3'>
                <input type="radio" id="html" name="fav_language" value="careers" />
                <label >Careers </label><br></br>
              </div>
              <div className='col-md-3'>
                <input type="radio" id="html" name="fav_language" value="general" ight="20" />
                <label className='pr-0' style={{ paddingRight: "0px" }}>General </label><br></br>
              </div>
            </div>
            <div>
              <form onSubmit={onSubmitForm}>
                <div className="mt-30">
                  <div >
                    <input
                      className='form-control-contact mr-20 '
                      style={{ width: "45%" }}
                      type='text'
                      placeholder='First Name *'
                      onChange={(e) => setFirstName(e.target.value)}
                    ></input>
                    <input
                      style={{ width: "45%" }}
                      className='form-control-contact ml-20 '
                      type='text'
                      placeholder='Last Name *'
                      onChange={(e) => setLastName(e.target.value)}
                    ></input>
                  </div>
                  {company_show == true ? (
                    <input
                      className='form-control-contact w-100'
                      type='text'
                      placeholder='Company Name *'
                      onChange={(e) => setCompany(e.target.value)}
                    ></input>
                  ) : (<></>)}


                  <input
                    className='form-control-contact w-100'
                    type='text'
                    placeholder='Email Address *'
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                  <input
                    className='form-control-contact w-100'
                    type='text'
                    placeholder='Phone Number *'
                    onChange={(e) => setPhone(e.target.value)}
                  ></input>
                  {show_college == true ? (
                    <>
                      <input
                        className='form-control-contact w-100'
                        type='text'
                        placeholder='College Name *'
                        onChange={(e) => setCollege(e.target.value)}
                      ></input>
                      <input
                        className='form-control-contact w-100'
                        type='file'
                        onChange={(e) => setFile(e.target.files[0])}
                        placeholder='Company Name *'
                      ></input>
                    </>
                  ) : (
                    <></>
                  )}

                  {show_message == true ? (
                    <textarea className='form-control-contact w-100'
                      placeholder='Message *'
                      onChange={(e) => setMessage(e.target.value)}
                    >
                    </textarea>) : (
                    <></>
                  )}

                  <div>
                    <input
                      type='checkbox'
                    ></input>
                    <label className='bd-text-check'>
                      Yes, I would like elfs to contact me.</label>
                  </div>

                  <div className='mt-20'>
                    <button className='btn-submit' >Submit</button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutIndex;
