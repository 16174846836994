import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {

    const [isLoading, setLoading] = useState(false)
    const [data, setdata] = useState([])

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setLoading(true)
        try {
            const response = await axios.get(
                "https://www.itelfs.com/admin/api/footer",
            )
            setdata(response.data.footer_section)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const openNav = () => {
        document.getElementById("myForm").style.width = "500px";
        document.getElementById("myForm").classList.add("resp-width-side-form")
    }

    function closeNav() {
        document.getElementById("myForm").style.width = "0";
        document.getElementById("myForm").classList.remove("resp-width-side-form")
    }

    return (
        <div>
            <div className='pt-20 bg-blue'>
                <div className='b-head-container bd-container-head' style={{ maxWidth: "1140px" }}>
                    <div className="display-flex pt-120 mobile-m responsive-web-flex mobile-pb-20">
                        <div className="col-md-4 mobile-w-100 mb-20 border-right-first">
                            <div className='pr-10 mobile-w-100'>
                                <div>
                                    <div className="mb-20 bd-footer-icon">
                                        <a href="/" className='text-white'>
                                            elfs
                                        </a>
                                    </div>
                                    <div className='col-md-10'>
                                    </div>
                                    <div className='mt-50'>
                                        <button onClick={openNav} className='bd-oval-btn' >Get in Touch</button>
                                        <span className="bg" />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-md-8 display-flex mobile-w-100   responsive-web-flex '>
                            <div className='col-md-6 pl-10 mobile-w-100 wpb-0 mobile-pl-0 mb-20 '>
                                <div className="bd-footer-sections border-right-second">
                                    <div>
                                        {/* <p className='mb-15' >Quick Links</p> */}
                                    </div>
                                    <div>
                                        <ul>
                                            <li className='mb-10'>
                                                <Link to="/about-us">About us</Link>
                                            </li>
                                            <li className='mb-10'>
                                                <Link to="/for-companies">For Companies</Link>
                                            </li>
                                            <li className='mb-10'>
                                                <Link to="/for-candidates"> For Candidates</Link>
                                            </li>
                                            <li className='mb-10'>
                                                <Link to="/inovation-lab">Inovation Lab</Link>
                                            </li>
                                            <li className='mb-10'>
                                                <Link to="#"> Media</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mobile-w-100 mb-20 border-right-third'>
                                <div className="bd-footer-sections  ml-90">
                                    <div >
                                        {/* <p className='mb-15' >Follow Us</p> */}
                                    </div>
                                    <div>
                                        <ul>
                                            <li className='mb-10' style={{ cursor: "pointer" }}>
                                                <i className="icon bi-telephone-fill pr-10"></i><a href='callto:+971 50 245 2989'>+971 50 245 2989</a></li>
                                            <li className='mb-10' style={{ cursor: "pointer" }}> <i className="bi bi-envelope-open-fill pr-10"></i><a href='mailto:hello@elfs.com'>hello@elfs.com</a></li>


                                        </ul>
                                    </div>

                                    <div>
                                        <div className='mobile-w-100 pr-20 mt-10'>
                                            <div className="bd-footer-sections no-left">
                                                <div className="text-white">
                                                    <i className="bi bi-linkedin mr-10" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                                    <strong>LinkedIn</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="b-foot-inner2 pb-10 pt-10">
                        <div className="display-flex responsive-web-flex">
                            <div className='col-md-3 mobile-w-100'>
                                <div>
                                    <h6 className='text-white bd-copy-text'>© 2023 elfs. All rights reserved </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className='b-head-container bd-container-head' style={{ maxWidth: "1140px" }}>
                    <div className="display-flex pt-120 mobile-m responsive-web-flex mobile-pb-20">
                        {data.footer?.map((item, i) => {
                            return (
                                <div className="col-md-4 mobile-w-100 mb-20 border-right-first" key={"foot-1" + i}>
                                    <div className='pr-10 mobile-w-100'>
                                        <div>
                                            <div className="mb-20 bd-footer-icon">
                                                <a>
                                                    elfs
                                                </a>
                                            </div>
                                            <div className='col-md-10'>
                                                <p className='b-foot '>
                                                    {item.footer_text}
                                                </p>
                                            </div>
                                            <div className='mt-50'>
                                                <a className='bd-oval-btn'>{item.newsletter_text}</a>
                                                <span className="bg" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}

                        <div className='col-md-8 display-flex mobile-w-100   responsive-web-flex '>
                            <div className='col-md-6 pl-10 mobile-w-100 wpb-0 mobile-pl-0 mb-20 '>
                                <div className="bd-footer-sections border-right-second">
                                    <div>
                                        <p className='mb-15' >Consulting</p>
                                    </div>
                                    <div>
                                        <ul>
                                            <li className='mb-10'>
                                                <Link to="/about-us">About us</Link>
                                            </li>
                                            <li className='mb-10'>
                                                <Link to="/for-business">For Business</Link>
                                            </li>
                                            <li className='mb-10'>
                                                <Link to="/for-candidates"> For Candidates</Link>
                                            </li>
                                            <li className='mb-10'>
                                                <Link to="/inovation-lab">Inovation Lab</Link>
                                            </li>
                                            <li className='mb-10'>
                                                <Link to="#"> Media</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mobile-w-100 mb-20 border-right-third'>
                                {data.footer_follow?.map((item, i) => {
                                    return (
                                        <div className="bd-footer-sections  ml-90" key={"foot-2" + i}>
                                            <div>
                                                <p className='mb-15' >Follow Us</p>
                                            </div>
                                            <div>
                                                <ul>
                                                    <li className='mb-10'>{item.footer_address}</li>
                                                    <li className='mb-10'><i className="bi bi-telephone-fill pr-10"></i>{item.footer_phone}</li>
                                                    <li className='mb-10'> <i className="bi bi-envelope-open-fill pr-10"></i>{item.footer_email}</li>
                                                </ul>
                                            </div>

                                            <div>
                                                <div className='mobile-w-100 pr-20 mt-30'>
                                                    <div className="bd-footer-sections">
                                                        <div className="bd-social-icon">
                                                            <a href={item.footer_fblink}>
                                                                <i className="bi bi-facebook pr-30 text-white" ></i>
                                                            </a>
                                                            <a href={item.footer_tellink}>
                                                                <i className="bi bi-telegram pr-30 text-white" ></i>
                                                            </a>
                                                            <a href={item.footer_ytlink}>
                                                                <i className="bi bi-youtube pr-30 text-white" ></i>
                                                            </a>
                                                            <a href={item.footer_ldlink}>
                                                                <i className="bi bi-linkedin text-white" ></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                    <div className="b-foot-inner2 pb-10 pt-10">
                        <div className="display-flex responsive-web-flex">
                            <div className='col-md-3 mobile-w-100'>
                                <div>
                                    <h6 className='text-white bd-copy-text'>© 2023 ELFS. All rights reserved </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Footer