import React, { useState } from 'react'
import Navbar from '../HomePage/Navbar'
import Select from 'react-select';
import Footer from '../HomePage/Footer';
import axios from 'axios';
import swal from 'sweetalert';
import Loader from '../Loader';


const Cvmaker = () => {

    // personal info
    const [photo, setphoto] = useState('')
    const [given_name, setgiven_name] = useState('')
    const [family_name, setfamily_name] = useState('')
    const [email, setemail] = useState('')
    const [headline, setheadline] = useState('')
    const [city, setcity] = useState('')
    const [phone_number, setphone_number] = useState('')
    const [address, setaddress] = useState('')
    const [post_code, setpost_code] = useState('')
    const [city_one, setcity_one] = useState('')

    // Education
    const [education, seteducation] = useState('')
    const [school, setschool] = useState('')
    const [scity, setscity] = useState('')
    const [start_month, setstart_month] = useState('')
    const [start_year, setstart_year] = useState('')
    const [end_month, setend_month] = useState('')
    const [end_year, setend_year] = useState('')
    const [description, setdescription] = useState('')

    // Employment
    const [position, setposition] = useState('')
    const [employer, setemployer] = useState('')
    const [emp_city, setemp_city] = useState('')
    const [emp_start_month, setemp_start_month] = useState('')
    const [emp_start_year, setemp_start_year] = useState('')
    const [emp_end_month, setemp_end_month] = useState('')
    const [emp_end_year, setemp_end_year] = useState('')
    const [enp_description, setenp_description] = useState('')

    // skills
    const [skills, setskills] = useState('')
    const [skills_level, setskills_level] = useState('Make a Choice')


    // languages
    const [language, setlanguage] = useState('')
    const [language_level, setlanguage_level] = useState('Make a Choice')

    // hobbies
    const [hobby, sethobby] = useState('')

    const [loading, setLoading] = useState(false);

    const Month = [
        { value: 'January', label: 'January' },
        { value: 'February', label: 'February' },
        { value: 'March', label: 'March' },
        { value: 'April', label: 'April' },
        { value: 'May', label: 'May' },
        { value: 'June', label: 'June' },
        { value: 'July', label: 'July' },
        { value: 'August', label: 'August' },
        { value: 'September', label: 'September' },
        { value: 'October', label: 'October' },
        { value: 'November', label: 'November' },
        { value: 'December', label: 'December' },
    ]
    const Year = [
        { value: "2023", label: "2023" },
        { value: "2022", label: "2022" },
        { value: "2021", label: "2021" },
        { value: "2020", label: "2020" },
        { value: "2019", label: "2019" },
        { value: "2018", label: "2018" },
        { value: "2017", label: "2017" },
        { value: "2016", label: "2016" },
        { value: "2015", label: "2015" },
        { value: "2014", label: "2014" },
        { value: "2013", label: "2013" },
        { value: "2012", label: "2012" },
        { value: "2011", label: "2011" },
        { value: "2010", label: "2010" },
        { value: "2009", label: "2009" },
        { value: "2008", label: "2008" },
    ]


    const onSubmitForm = async (e) => {
        e.preventDefault()
        
        setLoading(true)
        const formData = new FormData()

        formData.append('attachment', photo)
        formData.append('given_name', given_name)
        formData.append('family_name', family_name)
        formData.append('email', email)
        formData.append('headline', headline)
        formData.append('city', city)
        formData.append('phone_number', phone_number)
        formData.append('address', address)
        formData.append('post_code', post_code)
        formData.append('city_one', city_one)
        formData.append('education', education)
        formData.append('school', school)
        formData.append('scity', scity)
        formData.append('start_month', start_month)
        formData.append('start_year', start_year)
        formData.append('end_month', end_month)
        formData.append('end_year', end_year)
        formData.append('description', description)
        formData.append('position', position)
        formData.append('employer', employer)
        formData.append('emp_city', emp_city)
        formData.append('emp_start_month', emp_start_month)
        formData.append('emp_start_year', emp_start_year)
        formData.append('emp_end_month', emp_end_month)
        formData.append('emp_end_year', emp_end_year)
        formData.append('enp_description', enp_description)
        formData.append('skills', skills)
        formData.append('skills_level', skills_level)
        formData.append('language', language)
        formData.append('language_level', language_level)
        formData.append('hobby', hobby)

        try {
            const response = await axios({
                method: "post",
                url: "https://www.itelfs.com/admin/api/cv_maker",
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {

                swal({
                    icon: "success",
                    text: "Data Recorded Successfully",
                }).then(data => {
                    window.location.reload()
                })

                setLoading(false)
            })
        } catch (error) {
            swal({
                icon: "error",
                text: "Something Went Wrong",
            })
            setLoading(false)
            console.log(error)
        }
    }


    const skillLevel = (e) => {
        const input = e.target.value;

        setskills_level(
            input == 0 ? "Make a Choice" : input == 1 ? "Beginner" : 
            input == 2 ? "Moderate" : input == 3 ? "Good" : input == 4 ? "Very good" : "Excellent"
        )
    }

    const langLevel = (e) => {
        const input = e.target.value
        setlanguage_level(
            input == 0 ? "Make a Choice" : input == 1 ? "Beginner" : 
            input == 2 ? "Moderate" : input == 3 ? "Good" : input == 4 ? "Very good" : "Excellent"
        )
    }


    return (
        <div>
            {loading && (<Loader />)}

            <Navbar />

            <section>
                <div className='swiper-container'>
                    <div className="bd-single-slider-overlay  d-flex align-items-center swiper-slide bd-banner-height">
                        <div className="bd-slide-bg" style={{ opacity: "0.38" }}>
                            <img alt="No img" src='https://cdn.pixabay.com/photo/2020/07/08/04/12/work-5382501_640.jpg' className='w-100 respon-height' />
                        </div>
                        <div className="container">
                            <div className="row ">
                                <div className="col-12 ">
                                    <div className=" z-index text-center" style={{ position: "relative", zIndex: "999", top: "30px" }}>
                                        <h1 className="bd-slider-title mb-20 bd-icon-gradient" >CV Maker</h1>
                                        <p className="mb-40 text-white bd-banner-desc" >Create a professional CV in minutes with our easy-to-use CV Maker</p>
                                        <div className="bd-slider-btn">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mt-80 mb-80'>
                <div className='b-head-container container-fluid bd-container-head'>
                    <form onSubmit={onSubmitForm}>
                        <div className='mb-70 bd-box-form'>
                            <div className='mb-40'>
                                <h1 className='bd-head-form'>Personal Details</h1>
                            </div>
                            <div className='row'>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>Photo</label>
                                    <input
                                        className='custome-font-form'
                                        type='file'
                                        onChange={(e) => setphoto(e.target.files[0])}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label '>Given name</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Given name'
                                        type='text'
                                        value={given_name}
                                        onChange={(e) => setgiven_name(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>Family name</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Family name'
                                        type='text'
                                        value={family_name}
                                        onChange={(e) => setfamily_name(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>Email address</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Email address'
                                        type='email'
                                        value={email}
                                        onChange={(e) => setemail(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-8 mb-40'>
                                    <label className='form-label'>Headline</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Headline'
                                        type='text'
                                        value={headline}
                                        onChange={(e) => setheadline(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>City</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter City'
                                        type='text'
                                        value={city}
                                        onChange={(e) => setcity(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>Phone number</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Phone number'
                                        type='text'
                                        value={phone_number}
                                        onChange={(e) => setphone_number(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>Address</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Address'
                                        type='text'
                                        value={address}
                                        onChange={(e) => setaddress(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>Post code</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Post code'
                                        type='text'
                                        value={post_code}
                                        onChange={(e) => setpost_code(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>City</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter City'
                                        type='text'
                                        value={city_one}
                                        onChange={(e) => setcity_one(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='mb-70 bd-box-form'>
                            <div className='mb-40'>
                                <h1 className='bd-head-form'>Education</h1>
                            </div>
                            <div className='row align-items-end'>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>Education</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Education'
                                        type='text'
                                        value={education}
                                        onChange={(e) => seteducation(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>School</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter School'
                                        type='text'
                                        value={school}
                                        onChange={(e) => setschool(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>City</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter City'
                                        type='text'
                                        value={scity}
                                        onChange={(e) => setscity(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-3 mb-40'>
                                    <label className='form-label'>Start</label>
                                    <Select
                                        options={Month}
                                        placeholder=" Month *"
                                        onChange={(e) => setstart_month(e.value)}

                                    />
                                </div>
                                <div className='col-md-3 mb-40'>
                                    <Select
                                        options={Year}
                                        placeholder=" Year *"
                                        onChange={(e) => setstart_year(e.value)}

                                    />
                                </div>
                                <div className='col-md-3 mb-40'>
                                    <label className='form-label'>End</label>
                                    <Select
                                        options={Month}
                                        placeholder=" Month *"
                                        onChange={(e) => setend_month(e.value)}

                                    />
                                </div>
                                <div className='col-md-3 mb-40'>
                                    <Select
                                        options={Year}
                                        placeholder=" Year *"
                                        onChange={(e) => setend_year(e.value)}

                                    />
                                </div>
                                <div className='col-md-12 mb-40'>
                                    <label className='form-label'>Description</label>
                                    <textarea
                                        className='custome-font-form'
                                        placeholder='Enter Description'
                                        value={description}
                                        onChange={(e) => setdescription(e.target.value)}
                                        style={{ height: "230px" }}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className='mb-70 bd-box-form'>
                            <div className='mb-40'>
                                <h1 className='bd-head-form'>Employment </h1>
                            </div>
                            <div className='row align-items-end '>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>Position</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Position'
                                        type='text'
                                        value={position}
                                        onChange={(e) => setposition(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>Employer</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Employer'
                                        type='text'
                                        value={employer}
                                        onChange={(e) => setemployer(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <label className='form-label'>City</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter City'
                                        type='text'
                                        value={emp_city}
                                        onChange={(e) => setemp_city(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-3 mb-40'>
                                    <label className='form-label'>Start</label>
                                    <Select
                                        options={Month}
                                        placeholder=" Month *"
                                        onChange={(e) => setemp_start_month(e.value)}

                                    />
                                </div>
                                <div className='col-md-3 mb-40'>
                                    <Select
                                        options={Year}
                                        placeholder=" Year *"
                                        onChange={(e) => setemp_start_year(e.value)}

                                    />
                                </div>
                                <div className='col-md-3 mb-40'>
                                    <label className='form-label'>End</label>
                                    <Select
                                        options={Month}
                                        placeholder=" Month *"
                                        onChange={(e) => setemp_end_month(e.value)}

                                    />
                                </div>
                                <div className='col-md-3 mb-40'>
                                    <Select
                                        options={Year}
                                        placeholder=" Year *"
                                        onChange={(e) => setemp_end_year(e.value)}

                                    />
                                </div>
                                <div className='col-md-12 mb-40'>
                                    <label className='form-label'>Description</label>
                                    <textarea
                                        className='custome-font-form'
                                        placeholder='Enter Description'
                                        type='text'
                                        style={{ height: "230px" }}
                                        value={enp_description}
                                        onChange={(e) => setenp_description(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className='mb-70 bd-box-form' >
                            <div className='mb-40'>
                                <h1 className='bd-head-form'>Skills</h1>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-md-12 mb-40'>
                                    <label className='form-label'>Skills</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Skills'
                                        type='text'
                                        value={skills}
                                        onChange={(e) => setskills(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40' >
                                    <label className='form-label'>Level</label>
                                    <input id="8117303b-4d89-43c2-88ca-0d0ac4c89975" type="range" size="1" class="user-select-none w-full py-3 cursor-pointer border" min="0" max="5" step="1" style={{ outline: "none" }} defaultValue={0} onChange={skillLevel} />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <h5>{skills_level}</h5>
                                </div>
                            </div>
                        </div>

                        <div className='mb-70 bd-box-form' >
                            <div className='mb-40'>
                                <h1 className='bd-head-form'>Languages</h1>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-md-12 mb-40'>
                                    <label className='form-label'>Language</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Language'
                                        type='text'
                                        value={language}
                                        onChange={(e) => setlanguage(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-4 mb-40' >
                                    <label className='form-label'>Level</label>
                                    <input id="8117303b-4d89-43c2-88ca-0d0ac4c89975" type="range" size="1" class="user-select-none w-full py-3 cursor-pointer border" min="0" max="5" step="1" style={{ outline: "none" }} defaultValue={0} onChange={langLevel} />
                                </div>
                                <div className='col-md-4 mb-40'>
                                    <h5>{language_level}</h5>
                                </div>
                            </div>
                        </div>


                        <div className='mb-70 bd-box-form'>
                            <div className='mb-40'>
                                <h1 className='bd-head-form'>Hobbies</h1>
                            </div>
                            <div className='row align-items-end'>
                                <div className='col-md-12 mb-40'>
                                    <label className='form-label'>Hobby</label>
                                    <input
                                        className='custome-font-form'
                                        placeholder='Enter Hobby'
                                        type='text'
                                        value={hobby}
                                        onChange={(e) => sethobby(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='d-flex align-items-center  bd-resp-flex-column justify-space-between'>
                            <div>
                                <h5>Your CV will be downloaded as a .doc file.</h5>
                                <h5>This file you could attach to the job application.</h5>
                            </div>
                            <div>
                                <button type='submit' className='bd-all-job-btn border-0'>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

            <Footer />
        </div >
    )
}

export default Cvmaker