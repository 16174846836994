import React, { useEffect, useState } from 'react'
import Navbar from '../HomePage/Navbar'
import Footer from '../HomePage/Footer'
import axios from 'axios'
import Loader from '../Loader'
import { useRef } from 'react'

import Icon31 from "../../Assets/Images/adaptability(1).png"
import swal from 'sweetalert'

const InovationIndex = () => {

    const section1Ref = useRef(null);
    const section2Ref = useRef(null)
    const [isLoading, setLoading] = useState(true)
    const [data, setdata] = useState(null)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setLoading(true)
        try {
            const response = await axios.get(
                "https://www.itelfs.com/admin/api/innovation",
            )
            setdata(response.data.innovation_page)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }


    const scrollToSection = (section) => {
        let targetSection = null;

        switch (section) {
            case 'innovation1':
                targetSection = section1Ref.current;
                break;
            case 'innovation2':
                targetSection = section2Ref.current;
                break;
            default:
                break;
        }

        if (targetSection) {
            const topOffset = targetSection.offsetTop;
            targetSection.scrollIntoView({ top: topOffset, behavior: 'smooth' });
        }
    }


    // contact form
    const openNav = () => {
        document.getElementById("myForm").style.width = "500px";
    }

    function closeNav() {
        document.getElementById("myForm").style.width = "0";
    }

    document.title = "Innovation Lab | ELFS";

    const [purpose, setPurpose] = useState('business')

    const [company_show, setCompanyShow] = useState(true)
    const [show_college, setCollegeShow] = useState(false)
    const [show_message, setMessageShow] = useState(true)

    const onPurposeChange = (e) => {
        setPurpose(e.target.value)
        if (e.target.value == "careers" || e.target.value == 'general') {
            setCompanyShow(false)
        } else {
            setCompanyShow(true)
        }

        if (e.target.value == "careers") {
            setCollegeShow(true)
            setMessageShow(false)
        } else {
            setCollegeShow(false)
            setMessageShow(true)
        }
    }


    const [f_name, setFirstName] = useState('')
    const [l_name, setLastName] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [file, setFile] = useState('')
    const [college, setCollege] = useState('')
    const [message, setMessage] = useState('')



    const onSubmitForm = async e => {
        e.preventDefault()
        document.getElementById("myForm").style.width = "0";
        setLoading(true)
        const formData = new FormData()
        formData.append('purspose', purpose)
        formData.append('fname', f_name)
        formData.append('lname', l_name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('message', message)
        formData.append('company', company)
        formData.append('college', college)
        formData.append('attachment', file)
        try {
            const response = await axios({
                method: "post",
                url: "https://www.itelfs.com/admin/api/getintouch",
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {

                swal({
                    icon: "success",
                    text: "Data Recorded Successfully",
                }).then(data => {
                    window.location.reload()
                })

                setLoading(false)
            })
        } catch (error) {
            swal({
                icon: "error",
                text: "Something Went Wrong",
            })
            setLoading(false)
            console.log(error)
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            <Navbar scrollToSection={scrollToSection} />

            <section>
                <div className='swiper-container'>
                    {data?.innovation_breadcrumb?.map((bread, index) => {
                        return (
                            <div className="bd-single-slider-overlay  d-flex align-items-center swiper-slide bd-banner-height" key={index + "bread2"}>
                                <div className="bd-slide-bg" style={{ opacity: "0.38" }}>
                                    <img alt="no img" src={`https://www.itelfs.com/admin/assets/front/img//${bread.innovationbreadcrumb_bg}`} className='w-100 respon-height' key={index + "bread"} />
                                </div>
                                <div className="container" style={{ maxWidth: "710px" }}>
                                    <div className="row ">
                                        <div className="col-12 ">
                                            <div className=" z-index text-center" style={{ position: "relative", zIndex: "999", top: "30px" }}>
                                                <h1 className="bd-slider-title mb-20 bd-icon-gradient" >{bread.innovationbreadcrumb_heading}</h1>
                                                <p className="mb-40 text-white" >{bread.innovationbreadcrumb_overview}</p>
                                                <div className="bd-slider-btn">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>

            {/* get in touch btn */}
            <section>
                <div className=''>
                    <a className='side-fixed-btn text-white' onClick={openNav}>Get in Touch</a>
                </div>
            </section>

            <section className='mt-80 mb-80' ref={section1Ref}>
                {data?.innovation_lab?.map((item, i) => {
                    return (
                        <div key={'item' + i}>
                            <div className='text-center'>
                                <h2 className='bd-fourth-head-cand'>{item.innovation_heading}</h2>
                            </div>
                            <div className='b-head-container container-fluid bd-container-head mt-40' >
                                <div className='bd-inovative'>
                                    <p>{item.innovation_overview}</p>

                                    <p>{item.innovation_overview1}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </section>

            <section className='mt-100 mb-100 custom-margin' ref={section2Ref}>
                <div className='border-grey'>
                    <div className=' d-flex align-items-center bd-resp-flex-column'>
                        <div className='col-md-5 pl-60 pr-60 techies-padding'>
                            <div>
                                <h1 className='bd-third-head'>
                                    <span> {data?.innovation_product[0].productino_heading} </span>
                                    <br />{data?.innovation_product[0].productino_subheading}
                                </h1>
                            </div>
                            <div className='mt-20'>
                            </div>
                        </div>
                        <div className='col-md-7 pr-60 bd-padd'>
                            <h5 className='bd-head-font'>{data?.innovation_product[0].productino_title}</h5>
                            <div className="row bd-imp-row responsive-web-flex" >

                                {
                                    data?.products.map((product, i) => {
                                        return (
                                            <div key={i} className='col-md-4 col-sm-6 pr-10 pl-10 mobile-w-100 mb-20 mt-10'>
                                                <div className="h-310 no-hover">
                                                    <div className='bd-slide-card'></div>
                                                    <div className='text-center mb-20'>

                                                        <img alt="No img" src={`https://www.itelfs.com/admin/assets/front/img//${product.img}`} className='m-auto bd-grad' />
                                                        <img alt="No img" src={Icon31} className='m-auto bd-white ' />
                                                    </div>
                                                    <div className="mb-20 ">
                                                        <h3 className='bd-service-headings text-center'> {product.title} </h3>
                                                    </div>
                                                    <div className="bd-service-text " >
                                                        <p className='text-center' style={{ lineHeight: "26px" }}>
                                                            {product.overview}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mt-50 mb-90'>
                <div>
                    <div className='coming-soon-text'>
                        Coming soon... we can't wait to share it with you!
                    </div>
                </div>
            </section>

            {/* contact form */}
            <div id="myForm" className="form-sidenav" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' }}>
                <div className='bd-sidebars'>
                    <a className="closebtn" onClick={closeNav}>&times;</a>
                    <div className="contact-head mb-30">
                        <h3>Contact Us</h3>
                    </div>
                    <div>
                        <div className="d-flex bd-radio-btn" onChange={onPurposeChange}>
                            <div className='col-md-3 pr-20'>
                                <p>Purspose*</p>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="business" defaultChecked="true" />
                                <label >Business </label><br></br>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="careers" />
                                <label >Careers </label><br></br>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="general" ight="20" />
                                <label className='pr-0' style={{ paddingRight: "0px" }}>General </label><br></br>
                            </div>
                        </div>
                        <div>
                            <form onSubmit={onSubmitForm}>
                                <div className="mt-30">
                                    <div >
                                        <input
                                            className='form-control-contact mr-20 '
                                            style={{ width: "45%" }}
                                            type='text'
                                            placeholder='First Name *'
                                            onChange={(e) => setFirstName(e.target.value)}
                                        ></input>
                                        <input
                                            style={{ width: "45%" }}
                                            className='form-control-contact ml-20 '
                                            type='text'
                                            placeholder='Last Name *'
                                            onChange={(e) => setLastName(e.target.value)}
                                        ></input>
                                    </div>
                                    {company_show == true ? (
                                        <input
                                            className='form-control-contact w-100'
                                            type='text'
                                            placeholder='Company Name *'
                                            onChange={(e) => setCompany(e.target.value)}
                                        ></input>
                                    ) : (<></>)}


                                    <input
                                        className='form-control-contact w-100'
                                        type='text'
                                        placeholder='Email Address *'
                                        onChange={(e) => setEmail(e.target.value)}
                                    ></input>
                                    <input
                                        className='form-control-contact w-100'
                                        type='text'
                                        placeholder='Phone Number *'
                                        onChange={(e) => setPhone(e.target.value)}
                                    ></input>
                                    {show_college == true ? (
                                        <>
                                            <input
                                                className='form-control-contact w-100'
                                                type='text'
                                                placeholder='College Name *'
                                                onChange={(e) => setCollege(e.target.value)}
                                            ></input>
                                            <input
                                                className='form-control-contact w-100'
                                                type='file'
                                                onChange={(e) => setFile(e.target.files[0])}
                                                placeholder='Company Name *'
                                            ></input>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {show_message == true ? (
                                        <textarea className='form-control-contact w-100'
                                            placeholder='Message *'
                                            onChange={(e) => setMessage(e.target.value)}
                                        >
                                        </textarea>) : (
                                        <></>
                                    )}

                                    <div>
                                        <input
                                            type='checkbox'
                                        ></input>
                                        <label className='bd-text-check'>
                                            Yes, I would like elfs to contact me.</label>
                                    </div>

                                    <div className='mt-20'>
                                        <button className='btn-submit' >Submit</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default InovationIndex