import React from "react";
import "../../src/Assets/Css/seperate css/Loader.css"

const Loader = () => {
  return (
    <div>
      <div className="loader">
        <div className="loader-inner line-scale-pulse-out">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
