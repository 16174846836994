import React, { useState } from 'react'
import Navbar from '../HomePage/Navbar'
import Footer from '../HomePage/Footer'
import axios from 'axios'
import Loader from '../Loader'
import swal from 'sweetalert'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

const JobDetail = () => {

    const [purpose, setPurpose] = useState('business')

    const [company_show, setCompanyShow] = useState(true)
    const [show_college, setCollegeShow] = useState(false)
    const [show_message, setMessageShow] = useState(true)

    const [jobData, setJobData] = useState(null);
    const [isLoading, setLoading] = useState(false)

    const { vacancy_id } = useParams();


    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(
                "https://www.itelfs.com/admin/api/vacancies",
            )

            const newData = response.data.vacancies_page.vacancies.filter((job) => job.id == vacancy_id);
            setJobData(...newData)

            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const data = [
        "Be an excellent communicator between internal IT team members and external stakeholders.",
        "Have good understanding of software development fundamentals.",
        "Liaise with business owners/managers and translate Business Requirements to IT initiatives.",
        "Contribute to the formulation of strategies for the company development roadmap.",
        "Translate more complex client business requirements (e.g., more complex features and solutions) into functional and technical specification documents using standard templates where applicable.",
        "4 or more years working as a Business Analyst.",
        "Have proven interpersonal skills with ability to partner and influence across departments.",
        "Worked in mobile software development and/or projects with microservices architecture.",
        "Worked on projects involving Cloud technologies.",
        "Working experience as a team player who can prioritize well, communicate clearly and compellingly, effectively influencing across teams.",
        "Knowledgeable of emerging technologies related to Cloud services, Data Analytics, and Healthtech.",
        "Expert in using project management tools (JIRA).",
        "BS in Computer Science, MIS or similar field.",
        "Have strong work ethics.",
        "Proficient in Agile.",
    ]

    const openNav = () => {
        document.getElementById("myForm").style.width = "500px";
    }

    function closeNav() {
        document.getElementById("myForm").style.width = "0";
    }


    const onPurposeChange = (e) => {
        setPurpose(e.target.value)
        if (e.target.value == "careers" || e.target.value == 'general') {
            setCompanyShow(false)
        } else {
            setCompanyShow(true)
        }

        if (e.target.value == "careers") {
            setCollegeShow(true)
            setMessageShow(false)
        } else {
            setCollegeShow(false)
            setMessageShow(true)
        }
    }

    const [f_name, setFirstName] = useState('')
    const [l_name, setLastName] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [file, setFile] = useState('')
    const [college, setCollege] = useState('')
    const [message, setMessage] = useState('')

    const onSubmitForm = async e => {
        e.preventDefault()
        document.getElementById("myForm").style.width = "0";
        setLoading(true)
        const formData = new FormData()
        formData.append('purspose', purpose)
        formData.append('fname', f_name)
        formData.append('lname', l_name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('message', message)
        formData.append('company', company)
        formData.append('college', college)
        formData.append('attachment', file)
        try {
            const response = await axios({
                method: "post",
                url: "https://www.itelfs.com/admin/api/getintouch",
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {

                swal({
                    icon: "success",
                    text: "Data Recorded Successfully",
                }).then(data => {
                    window.location.reload()
                })

                setLoading(false)
            })
        } catch (error) {
            swal({
                icon: "error",
                text: "Something Went Wrong",
            })
            setLoading(false)
            console.log(error)
        }
    }


    // Apply for job 
    const [jobName, setJobName] = useState('')

    const onApplyForm = async (e) => {
        e.preventDefault()
        document.getElementById("myForm").style.width = "0";
        setLoading(true)
        const formData = new FormData()
        formData.append('fname', f_name)
        formData.append('lname', l_name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('message', jobName)
        formData.append('attachment', file)
        try {
            const response = await axios({
                method: "post",
                url: "https://www.itelfs.com/admin/api/apply_for",
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {

                swal({
                    icon: "success",
                    text: "Data Recorded Successfully",
                }).then(data => {
                    window.location.reload()
                })

                setLoading(false)
            })
        } catch (error) {
            swal({
                icon: "error",
                text: "Something Went Wrong",
            })
            setLoading(false)
            console.log(error)
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            <Navbar />
            <section>
                <div className='swiper-container'>
                    <div className="bd-single-slider-overlay  d-flex align-items-center swiper-slide bd-banner-height" >
                        <div className="bd-slide-bg" style={{ opacity: "0.38" }}>
                            <img alt="no img" src='https://cdn.pixabay.com/photo/2020/07/08/04/12/work-5382501_640.jpg' className='w-100 respon-height' />
                        </div>
                        <div className="container">
                            <div className="row ">
                                <div className="col-12 ">
                                    <div className=" z-index text-center" style={{ position: "relative", zIndex: "999", top: "30px" }}>
                                        <h1 className="bd-slider-title mb-20 bd-icon-gradient" >Highlight Your Strengths</h1>
                                        <p className="mb-40 text-white" >Showcase your unique skills and experiences to stand out from other candidates.</p>
                                        <div className="bd-slider-btn">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className=''>
                    <a className='side-fixed-btn text-white' onClick={openNav}>Get in Touch</a>
                </div>
            </section>

            <section className='pt-80 pb-80 bg-grey'>
                <div className='b-head-container container-fluid bd-container-head'>
                    <div className='bd-job-box'>
                        <div className='mb-10'>
                            <h1>Job Details</h1>
                        </div>
                        <div className='bd-top-details '>
                            <div className='row justify-space-between align-items-center'>
                                <div className='col-8'>
                                    <p> <strong>Role : </strong> {jobData?.vacancies_title}</p>
                                    <p> <strong>Job type : </strong>{jobData?.vacancies_job} </p>
                                    <p> <strong>Work Location : </strong> {jobData?.vacancies_location}</p>
                                    <p> <strong>Start Date  : </strong>
                                        {
                                            new Date(jobData?.vacancies_date).toLocaleDateString("en-US", { day: "numeric", month: "long", year: "numeric" })
                                        }</p>
                                    <p> <strong>Salary & Benefits  : </strong> {jobData?.vacancies_benefit}</p>
                                </div>
                                <div className=' it-summary-sect'>
                                    <div className='bd-summary'>
                                        <h6>Summary</h6>
                                    </div>
                                    <div>
                                        <p className='text-black'><strong>Published on : </strong>
                                            {jobData?.created_at &&
                                                new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(jobData?.created_at))
                                            }
                                        </p>
                                        <p className='text-black'><strong>Employment Status : </strong>
                                            {jobData?.vacancies_job}</p>
                                        <p className='text-black'><strong>Job Location : </strong>  {jobData?.vacancies_location}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bd-tect-details'>
                            <h4>Technologies and Skills Required:
                            </h4>
                            {/* {data.map((item) => {
                                return (
                                    <div className="d-flex">
                                        <i className='bi bi-arrow-right pr-10'></i>
                                        <p>{item}</p>
                                    </div>
                                )
                            })} */}

                            <div className=" mt-4"
                                dangerouslySetInnerHTML={{ __html: jobData?.vacancies_overview }} />

                        </div>
                        <div className='mt-50'>
                            <div className='mobile-mt-20'>
                                <button className='apply-job-btn' data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ border: "none" }} >Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

            <div id="myForm" className="form-sidenav" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' }}>
                <div className='bd-sidebars'>
                    <a className="closebtn" onClick={closeNav}>&times;</a>
                    <div className="contact-head mb-30">
                        <h3>Contact Us</h3>
                    </div>
                    <div>
                        <div className="d-flex bd-radio-btn" onChange={onPurposeChange}>
                            <div className='col-md-3 pr-20'>
                                <p>Purspose*</p>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="business" defaultChecked="true" />
                                <label >Business </label><br></br>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="careers" />
                                <label >Careers </label><br></br>
                            </div>
                            <div className='col-md-3'>
                                <input type="radio" id="html" name="fav_language" value="general" ight="20" />
                                <label className='pr-0' style={{ paddingRight: "0px" }}>General </label><br></br>
                            </div>
                        </div>
                        <div>
                            <form onSubmit={onSubmitForm}>
                                <div className="mt-30">
                                    <div >
                                        <input
                                            className='form-control-contact mr-20 '
                                            style={{ width: "45%" }}
                                            type='text'
                                            placeholder='First Name *'
                                            onChange={(e) => setFirstName(e.target.value)}
                                        ></input>
                                        <input
                                            style={{ width: "45%" }}
                                            className='form-control-contact ml-20 '
                                            type='text'
                                            placeholder='Last Name *'
                                            onChange={(e) => setLastName(e.target.value)}
                                        ></input>
                                    </div>
                                    {company_show == true ? (
                                        <input
                                            className='form-control-contact w-100'
                                            type='text'
                                            placeholder='Company Name *'
                                            onChange={(e) => setCompany(e.target.value)}
                                        ></input>
                                    ) : (<></>)}


                                    <input
                                        className='form-control-contact w-100'
                                        type='text'
                                        placeholder='Email Address *'
                                        onChange={(e) => setEmail(e.target.value)}
                                    ></input>
                                    <input
                                        className='form-control-contact w-100'
                                        type='text'
                                        placeholder='Phone Number *'
                                        onChange={(e) => setPhone(e.target.value)}
                                    ></input>
                                    {show_college == true ? (
                                        <>
                                            <input
                                                className='form-control-contact w-100'
                                                type='text'
                                                placeholder='College Name *'
                                                onChange={(e) => setCollege(e.target.value)}
                                            ></input>
                                            <input
                                                className='form-control-contact w-100'
                                                type='file'
                                                onChange={(e) => setFile(e.target.files[0])}
                                                placeholder='Company Name *'
                                            ></input>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {show_message == true ? (
                                        <textarea className='form-control-contact w-100'
                                            placeholder='Message *'
                                            onChange={(e) => setMessage(e.target.value)}
                                        >
                                        </textarea>) : (
                                        <></>
                                    )}

                                    <div>
                                        <input
                                            type='checkbox'
                                        ></input>
                                        <label className='bd-text-check'>
                                            Yes, I would like elfs to contact me.</label>
                                    </div>

                                    <div className='mt-20'>
                                        <button className='btn-submit' >Submit</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal z-9999 fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header" style={{ borderBottom: "none" }}>
                            <h1 className="modal-title  custom-modal" id="staticBackdropLabel">Apply For Job</h1>
                            <button type="button" className="btn-close mb-10" data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "12px", fontWeight: '700' }}></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onApplyForm}>
                                <div className="mt-10">
                                    <div >
                                        <input
                                            className='display-hide'
                                            type='text'
                                            value="careers"

                                        />
                                        <input
                                            className='form-control-contact mr-15 modal-form'
                                            style={{ width: "46.5%" }}
                                            type='text'
                                            placeholder='First Name *'
                                            onChange={(e) => setFirstName(e.target.value)}
                                        ></input>
                                        <input
                                            style={{ width: "46.5%" }}
                                            className='form-control-contact ml-15 modal-form '
                                            type='text'
                                            placeholder='Last Name *'
                                            onChange={(e) => setLastName(e.target.value)}
                                        ></input>
                                    </div>

                                    <input
                                        className='form-control-contact w-100  modal-form'
                                        type='text'
                                        placeholder='Email Address *'
                                        onChange={(e) => setEmail(e.target.value)}
                                    ></input>
                                    <input
                                        className='form-control-contact w-100 modal-form'
                                        type='text'
                                        placeholder='Phone Number *'
                                        onChange={(e) => setPhone(e.target.value)}
                                    ></input>
                                    <input
                                        className='form-control-contact w-100 modal-form'
                                        type='text'
                                        placeholder='Applying For *'
                                        onChange={(e) => setJobName(e.target.value)}
                                    ></input>
                                    <div className='position-relative'>
                                        <input
                                            className='form-control-contact mb-0 w-100 modal-form  border-none'
                                            type='file'
                                            onChange={(e) => setFile(e.target.files[0])}
                                            placeholder='Company Name *'
                                        ></input>
                                        <div className="overlay-layer">
                                            <p className='mb-0'>{file.length == 0 ? "Upload CV" : file.name}</p><i className='bi bi-upload'></i>
                                        </div>
                                    </div>
                                    <div className='mobile-mt-20 mt-30 '>
                                        <button className='apply-job-btn' data-bs-dismiss="modal" aria-label="Close" style={{ border: "none" }} onClick={() => setPurpose('careers')} >Apply</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default JobDetail